import React, { useMemo } from "react";
import LinearProgress, { LinearProgressProps } from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const LinearProgressWithLabel = (props: LinearProgressProps & { value: number }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography
          variant="body2"
          sx={{ color: "text.secondary" }}
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
};

interface ProgressBarProps {
  totalSize: number;
  progressCount: number;
}

export default function ProgressBar({
  totalSize,
  progressCount,
}: ProgressBarProps) {
  const progress = useMemo(() => {
    if (totalSize > 0) {
      return (progressCount / totalSize) * 100;
    }
    return 0;
  }, [progressCount, totalSize]);

  return (
    <Box sx={{ width: "100%" }}>
      <LinearProgressWithLabel value={progress} />
    </Box>
  );
}