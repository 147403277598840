import * as THREE from "three";
import { IrrRange } from "./useIrrHeatmap";

export default function simHeatmapShaders(
  zenerPoss: THREE.Vector3[],
  lutTexture: THREE.DataTexture,
  irrRange: IrrRange,
  irrInterpDists: number[],
  irrInterpSettledIrrs: number[],
  irrInterpBoostIrrs: number[],
  angInterpAng: number[],
  angInterpPerc: number[],
) {
  if (
    irrInterpDists.length !== irrInterpBoostIrrs.length ||
    irrInterpDists.length !== irrInterpSettledIrrs.length ||
    angInterpAng.length !== angInterpPerc.length
  ) {
    throw new Error("Input arrays must be equal");
  }

  return {
    uniforms: {
      lutTexture: { value: lutTexture },
      minIrr: { value: irrRange.minIrr },
      maxIrr: { value: irrRange.maxIrr },
      zenerPoss: { value: zenerPoss },
      irrInterpDists: { value: irrInterpDists },
      irrInterpSettledIrrs: { value: irrInterpSettledIrrs },
      irrInterpBoostIrrs: { value: irrInterpBoostIrrs },
      angInterpAng: { value: angInterpAng },
      angInterpPerc: { value: angInterpPerc },
    },

    header: `
        varying vec3 vWorldPosition;
        uniform sampler2D lutTexture;

        uniform float minIrr;
        uniform float maxIrr;
        uniform vec3 zenerPoss[${zenerPoss.length}];

        uniform float irrInterpDists[${irrInterpDists.length}];
        uniform float irrInterpSettledIrrs[${irrInterpDists.length}];
        uniform float irrInterpBoostIrrs[${irrInterpDists.length}];

        uniform float angInterpAng[${angInterpAng.length}];
        uniform float angInterpPerc[${angInterpAng.length}];

        // --- Akima Interpolator

        int akiIrrFindFirstLn(in float x) {
          if (x > irrInterpDists[irrInterpDists.length() - 1]) {
            return -1;
          }

          for (int i = irrInterpDists.length() - 2; i >= 0; i--) {
            if (irrInterpDists[i] <= x) {
              return i;
            }
          }

          return -1;
        }

        int akiAngFindFirstLn(in float x) {
          if (x > angInterpAng[angInterpAng.length() - 1]) {
            return -1;
          }

          for (int i = angInterpAng.length() - 2; i >= 0; i--) {
            if (angInterpAng[i] <= x) {
              return i;
            }
          }

          return -1;
        }

        float akiCalcSlope(in float x1, in float x2, in float y1, in float y2) {
          float numer = y2 - y1;
          float denom = x2 - x1;
          return numer / denom;
        }

        float akiIrrItp(in float yis[${irrInterpDists.length}], in float x) {
          // --- Find i ---
          int i = akiIrrFindFirstLn(x);

          bool out_of_bounds = i < 0 || i >= irrInterpDists.length() - 1;
          if (out_of_bounds) {
            // Out-of-bounds.
            return -1.0;
          }

          // --- Calculate slopes ---
          float x_i = irrInterpDists[i];
          float y_i = yis[i];
          float x_ip1 = irrInterpDists[i + 1];
          float y_ip1 = yis[i + 1];

          float m_i = akiCalcSlope(x_i, x_ip1, y_i, y_ip1);
          float m_in2;
          float m_in1;
          float m_ip1;
          float m_ip2;

          if (i == 0) {
            // Generate 2 slopes to the left.
            float x_ip2 = irrInterpDists[i + 2];
            float y_ip2 = yis[i + 2];
            float m_ip1 = akiCalcSlope(x_ip1, x_ip2, y_ip1, y_ip2);

            m_in1 = 2.0 * m_i - m_ip1;
            m_in2 = 2.0 * m_in1 - m_i;
          } else if (i == 1) {
            // Generate 1 slope to the left.
            float x_in1 = irrInterpDists[i - 1];
            float y_in1 = yis[i - 1];

            m_in1 = akiCalcSlope(x_in1, x_i, y_in1, y_i);
            m_in2 = 2.0 * m_in1 - m_i;
          } else {
            float x_in2 = irrInterpDists[i - 2];
            float y_in2 = yis[i - 2];
            float x_in1 = irrInterpDists[i - 1];
            float y_in1 = yis[i - 1];

            m_in2 = akiCalcSlope(x_in2, x_in1, y_in2, y_in1);
            m_in1 = akiCalcSlope(x_in1, x_i, y_in1, y_i);
          }

          if (i == irrInterpDists.length() - 2) {
            float x_in1 = irrInterpDists[i - 1];
            float y_in1 = yis[i - 1];
            float m_in1 = akiCalcSlope(x_in1, x_i, y_in1, y_i);

            m_ip1 = 2.0 * m_i - m_in1;
            m_ip2 = 2.0 * m_ip1 - m_i;
          } else if (i == irrInterpDists.length() - 3) {
            // Generate 1 slope to the right.
            float x_ip2 = irrInterpDists[i + 2];
            float y_ip2 = yis[i + 2];

            m_ip1 = akiCalcSlope(x_ip1, x_ip2, y_ip1, y_ip2);
            m_ip2 = 2.0 * m_ip1 - m_i;
          } else {
            float x_ip2 = irrInterpDists[i + 2];
            float y_ip2 = yis[i + 2];
            float x_ip3 = irrInterpDists[i + 3];
            float y_ip3 = yis[i + 3];

            m_ip1 = akiCalcSlope(x_ip1, x_ip2, y_ip1, y_ip2);
            m_ip2 = akiCalcSlope(x_ip2, x_ip3, y_ip2, y_ip3);
          }

          float s_i;
          float s_ip1;

          float s_i_denom = abs(m_ip1 - m_i) + abs(m_in1 - m_in2);

          if (s_i_denom == 0.0) {
            s_i = (m_in1 + m_i) / 2.0;
          } else {
            s_i = (abs(m_ip1 - m_i) * m_in1 + abs(m_in1 - m_in2) * m_i) / s_i_denom;
          }

          float s_ip1_denom = abs(m_ip2 - m_ip1) + abs(m_i - m_in1);
          if (s_ip1_denom == 0.0) {
            s_ip1 = (m_i + m_ip1) / 2.0;
          } else {
            s_ip1 = (abs(m_ip2 - m_ip1) * m_i + abs(m_i - m_in1) * m_ip1) / s_ip1_denom;
          }

          // --- Calculate result polynomial ---

          float a_i = y_i;
          float b_i = s_i;
          float c_i = (3.0 * m_i - 2.0 * s_i - s_ip1) / (x_ip1 - x_i);

          float d_i = s_i + s_ip1 - 2.0 * m_i;
          d_i /= (x_ip1 - x_i) * (x_ip1 - x_i);

          float x_d = x - x_i;
          return a_i + b_i * x_d + c_i * x_d * x_d + d_i * x_d * x_d * x_d;
        }

        float akiAngItp(in float x) {
          // --- Find i ---
          int i = akiAngFindFirstLn(x);

          bool out_of_bounds = i < 0 || i >= angInterpAng.length() - 1;
          if (out_of_bounds) {
            // Out-of-bounds.
            return -1.0;
          }

          // --- Calculate slopes ---
          float x_i = angInterpAng[i];
          float y_i = angInterpPerc[i];
          float x_ip1 = angInterpAng[i + 1];
          float y_ip1 = angInterpPerc[i + 1];

          float m_i = akiCalcSlope(x_i, x_ip1, y_i, y_ip1);
          float m_in2;
          float m_in1;
          float m_ip1;
          float m_ip2;

          if (i == 0) {
            // Generate 2 slopes to the left.
            float x_ip2 = angInterpAng[i + 2];
            float y_ip2 = angInterpPerc[i + 2];
            float m_ip1 = akiCalcSlope(x_ip1, x_ip2, y_ip1, y_ip2);

            m_in1 = 2.0 * m_i - m_ip1;
            m_in2 = 2.0 * m_in1 - m_i;
          } else if (i == 1) {
            // Generate 1 slope to the left.
            float x_in1 = angInterpAng[i - 1];
            float y_in1 = angInterpPerc[i - 1];

            m_in1 = akiCalcSlope(x_in1, x_i, y_in1, y_i);
            m_in2 = 2.0 * m_in1 - m_i;
          } else {
            float x_in2 = angInterpAng[i - 2];
            float y_in2 = angInterpPerc[i - 2];
            float x_in1 = angInterpAng[i - 1];
            float y_in1 = angInterpPerc[i - 1];

            m_in2 = akiCalcSlope(x_in2, x_in1, y_in2, y_in1);
            m_in1 = akiCalcSlope(x_in1, x_i, y_in1, y_i);
          }

          if (i == angInterpAng.length() - 2) {
            float x_in1 = angInterpAng[i - 1];
            float y_in1 = angInterpPerc[i - 1];
            float m_in1 = akiCalcSlope(x_in1, x_i, y_in1, y_i);

            m_ip1 = 2.0 * m_i - m_in1;
            m_ip2 = 2.0 * m_ip1 - m_i;
          } else if (i == angInterpAng.length() - 3) {
            // Generate 1 slope to the right.
            float x_ip2 = angInterpAng[i + 2];
            float y_ip2 = angInterpPerc[i + 2];

            m_ip1 = akiCalcSlope(x_ip1, x_ip2, y_ip1, y_ip2);
            m_ip2 = 2.0 * m_ip1 - m_i;
          } else {
            float x_ip2 = angInterpAng[i + 2];
            float y_ip2 = angInterpPerc[i + 2];
            float x_ip3 = angInterpAng[i + 3];
            float y_ip3 = angInterpPerc[i + 3];

            m_ip1 = akiCalcSlope(x_ip1, x_ip2, y_ip1, y_ip2);
            m_ip2 = akiCalcSlope(x_ip2, x_ip3, y_ip2, y_ip3);
          }

          float s_i;
          float s_ip1;

          float s_i_denom = abs(m_ip1 - m_i) + abs(m_in1 - m_in2);

          if (s_i_denom == 0.0) {
            s_i = (m_in1 + m_i) / 2.0;
          } else {
            s_i = (abs(m_ip1 - m_i) * m_in1 + abs(m_in1 - m_in2) * m_i) / s_i_denom;
          }

          float s_ip1_denom = abs(m_ip2 - m_ip1) + abs(m_i - m_in1);
          if (s_ip1_denom == 0.0) {
            s_ip1 = (m_i + m_ip1) / 2.0;
          } else {
            s_ip1 = (abs(m_ip2 - m_ip1) * m_i + abs(m_i - m_in1) * m_ip1) / s_ip1_denom;
          }

          // --- Calculate result polynomial ---

          float a_i = y_i;
          float b_i = s_i;
          float c_i = (3.0 * m_i - 2.0 * s_i - s_ip1) / (x_ip1 - x_i);

          float d_i = s_i + s_ip1 - 2.0 * m_i;
          d_i /= (x_ip1 - x_i) * (x_ip1 - x_i);

          float x_d = x - x_i;
          return a_i + b_i * x_d + c_i * x_d * x_d + d_i * x_d * x_d * x_d;
        }

        float akiCalcRadialWeightedIrr(in float distM) {
          // Check if distM is within bounds.
          if (distM >= irrInterpDists[0] && distM <= irrInterpDists[irrInterpDists.length() - 1]) {
            return
              akiIrrItp(irrInterpBoostIrrs, distM) * 11.4 / 64.0
              + akiIrrItp(irrInterpSettledIrrs, distM) * (64.0 - 11.4) / 64.0;
          }

          // For out-of-bounds distM, calculate the scaling factor a.
          float boundaryX;
          if (distM < irrInterpDists[0]) {
            boundaryX = irrInterpDists[0];
          } else {
            boundaryX = irrInterpDists[irrInterpDists.length() - 1];
          }

          float a =
            akiIrrItp(irrInterpBoostIrrs, boundaryX) * 11.4 / 64.0
            + akiIrrItp(irrInterpSettledIrrs, boundaryX) * (64.0 - 11.4) / 64.0;

          // Use inverse square root.
          return a / (distM * distM);
        }

        float akiCalcIrrAngCoeff(in float angRad) {
          // Check if angRad is within bounds
          if (angRad < angInterpAng[0] || angRad > angInterpAng[angInterpAng.length() - 1]) {
            return 0.0;
          }

          return akiAngItp(angRad);
        }

        float akiCalcWeightedIrr(in float distM, in float angRad) {
          float radialIrr = akiCalcRadialWeightedIrr(distM);
          float angCoeff = akiCalcIrrAngCoeff(angRad);
          return radialIrr * angCoeff;
        }

        // !-- Akima Interpolator

      `,

    vertex: {
      "#include <project_vertex>": `
          vWorldPosition = (modelMatrix * vec4(position, 1.0)).xyz;
          gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        `,
    },
    fragment: {
      "#include <color_fragment>": `
          float totalIrradiance = 0.0;
          
          for (int i = 0; i < ${zenerPoss.length}; i++) {
            vec3 lightDir = normalize(vWorldPosition - zenerPoss[i]);
            vec3 downDir = vec3(0.0, 0.0, -1.0);

            float dist = length(zenerPoss[i] - vWorldPosition);
            float angle = acos(dot(lightDir, downDir));

            float irr = 0.0;
            totalIrradiance += akiCalcWeightedIrr(dist, angle);
          }
          
          // Normalize irradiance to [0, 1]
          float normalizedIrradiance = clamp((totalIrradiance - minIrr) / (maxIrr - minIrr), 0.0, 1.0);
    
          // Invert for LUT mapping (0 -> red, 1 -> blue)
          float intensity = 1.0 - normalizedIrradiance;
          vec3 color = texture2D(lutTexture, vec2(intensity, 0.5)).rgb;
          diffuseColor.rgb = color;
        `,
    },
  };
}
