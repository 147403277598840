import Axios, { AxiosResponse, CancelTokenSource } from "axios";
import axios from "./axios";
import { DbQuery, RoomScan } from "../types";
import * as NetUtils from "./utils";

export const ROOM_SCANS_URL = "/room_scans";
export const GET_ROOM_SCANS_URL = `${ROOM_SCANS_URL}/query`;
export const GET_ROOM_SCANS_FILES_URL = `${GET_ROOM_SCANS_URL}/files`;
export const GET_ROOM_SCANS_COUNT_URL = `${GET_ROOM_SCANS_URL}/count`;

export type GetRoomScansQuery = DbQuery<RoomScan>;

// Fetch RoomScans data for a list of rooms, retrieves metadata only without scan files.
export async function getRoomScans(
  query: GetRoomScansQuery,
  cancelTokenSourceOrSignal?: CancelTokenSource | AbortSignal,
  offset?: number,
  count?: number,
): Promise<RoomScan[]> {
  try {
    const resp: AxiosResponse = await axios.post(GET_ROOM_SCANS_URL, query, {
      params: {
        offset,
        count,
      },
      ...NetUtils.genAbortPayload(cancelTokenSourceOrSignal),
    });

    if (!Array.isArray(resp.data)) {
      console.warn(`${GET_ROOM_SCANS_URL} payload is not an array:`, resp.data);
      return [];
    }

    return resp.data as RoomScan[];
  } catch (error) {
    {
      if (Axios.isCancel(error)) {
        return;
      }
      throw error;
    }
  }
}

// Fetch RoomScans data with full scan files for rendering purposes.
export async function getRoomScansFiles(
  query: GetRoomScansQuery,
  cancelTokenSourceOrSignal?: CancelTokenSource | AbortSignal,
  offset?: number,
  count?: number,
): Promise<RoomScan[]> {
  try {
    const resp: AxiosResponse = await axios.post(
      GET_ROOM_SCANS_FILES_URL,
      query,
      {
        params: {
          offset,
          count,
        },
        ...NetUtils.genAbortPayload(cancelTokenSourceOrSignal),
      },
    );

    if (!Array.isArray(resp.data)) {
      console.warn(`${GET_ROOM_SCANS_URL} payload is not an array:`, resp.data);
      return [];
    }

    return resp.data as RoomScan[];
  } catch (error) {
    {
      if (Axios.isCancel(error)) {
        return;
      }
      throw error;
    }
  }
}

export async function getRoomScansCount(
  roomScansQuery: DbQuery<RoomScan> = {},
  cancelTokenSourceOrSignal?: CancelTokenSource | AbortSignal,
): Promise<number | null> {
  try {
    const resp: AxiosResponse = await axios.post(
      GET_ROOM_SCANS_COUNT_URL,
      roomScansQuery,
      {
        ...NetUtils.genAbortPayload(cancelTokenSourceOrSignal),
      },
    );
    return resp.data?.count;
  } catch (error) {
    {
      if (Axios.isCancel(error)) {
        return;
      }
      throw error;
    }
  }
}
