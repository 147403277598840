import { useEffect, useMemo, useState } from "react";
import { TransformControls } from "three/examples/jsm/Addons.js";
import * as THREE from "three";
import { UseRendererRes } from "./useRenderer";
import { checkIfZenerAboveFloor } from "./useZenerCeilingPos";
import { clickRaycast } from "../../utils/threejs";

export default function useDragZener(
  canvasRef: React.RefObject<HTMLCanvasElement | null>,
  renderCtx: UseRendererRes,
  zenerPositions: THREE.Vector3[],
  setZenerPositions: React.Dispatch<React.SetStateAction<THREE.Vector3[]>>,
) {
  const [transformControls, setTransformControls] =
    useState<TransformControls | null>(null);
  const [isDraggingZener, setIsDraggingZener] = useState(false);

  const { renderer, controls, scene, camera, render } = renderCtx;

  const roomModelHash =
    scene?.getObjectByName("RoomModel")?.userData.uvxRoomModelUpdatedAt;

  const floorModel = useMemo(() => {
    return scene?.getObjectByName("Floor0");
  }, [roomModelHash]);

  useEffect(() => {
    if (!renderer || !floorModel) {
      return;
    }

    function dragChangeHdlr(evt) {
      const nextDragging = evt.value;

      controls.enabled = !nextDragging;
      setIsDraggingZener(nextDragging);

      // Update zener positions.
      if (!nextDragging) {
        const zenersGroup = scene.getObjectByName("Zeners");
        const zeners = [];
        zenersGroup.traverse((child) => {
          if (
            !(child instanceof THREE.Mesh) ||
            child.name !== "createZenerMesh"
          ) {
            return;
          }
          zeners.push(child);
        });
        zeners.sort((a, b) => a.id - b.id);
        setZenerPositions(zeners.map((z) => z.position));
      }
    }

    function objectChangeHdlr(evt) {
      if (!floorModel) {
        return;
      }

      const zener = evt.target.object;

      // Restrict drag to above the floor.
      const isAboveFloor = checkIfZenerAboveFloor(floorModel, zener.position);
      if (!isAboveFloor && zener.userData.prevDragPos !== undefined) {
        zener.position.copy(zener.userData.prevDragPos);
        return;
      }

      // Update prevDragPos.
      if (zener.userData.prevDragPos === undefined) {
        zener.userData.prevDragPos = zener.position.clone();
      } else {
        zener.userData.prevDragPos.copy(zener.position);
      }

      // Update FoV cone.
      const fovCone = zener.userData.fovCone;
      fovCone.position.set(
        zener.position.x,
        zener.position.y,
        zener.position.z / 2,
      );

      const roomModel = scene.getObjectByName("RoomModel");
      if (!roomModel) {
        return;
      }

      const nextZenerPossUniform = [...zenerPositions];
      nextZenerPossUniform[zener.userData.index] = zener.position;

      // Update zener position uniforms while dragging, will commit to zener positions state on drag end.
      roomModel.traverse((child) => {
        if (
          child instanceof THREE.Mesh &&
          child.material?.uniforms?.zenerPoss
        ) {
          child.material.uniforms.zenerPoss = {
            value: nextZenerPossUniform,
          };
        }
      });

      // Update position of updated Zener's light.
      const zenerLights = scene.getObjectByName("ZenerLights");
      if (!zenerLights) {
        return;
      }

      zenerLights.traverse((child) => {
        if (child.userData?.zenerIndex === zener.userData.index) {
          child.position.copy(zener.position);
        }
      });
    }

    const nextTransformControls = new TransformControls(
      camera,
      renderer.domElement,
    );

    nextTransformControls.showZ = false;
    nextTransformControls.translationSnap = 0.4;

    nextTransformControls.addEventListener("change", render);
    nextTransformControls.addEventListener("dragging-changed", dragChangeHdlr);
    nextTransformControls.addEventListener("objectChange", objectChangeHdlr);

    setTransformControls(nextTransformControls);

    return () => {
      nextTransformControls.removeEventListener("change", render);
      nextTransformControls.removeEventListener(
        "dragging-changed",
        dragChangeHdlr,
      );
      nextTransformControls.removeEventListener(
        "objectChange",
        objectChangeHdlr,
      );

      nextTransformControls.detach();
    };
  }, [render, controls, renderer, zenerPositions, floorModel]);

  // Attach translate click event listener.
  useEffect(() => {
    function handleCanvasClick(evt: MouseEvent) {
      evt.preventDefault();

      if (!canvasRef.current || !transformControls) {
        return;
      }

      const intersections = clickRaycast(
        canvasRef.current,
        scene,
        camera,
        evt,
        scene,
        "Zeners",
      );

      if (intersections.length === 0) {
        if (!transformControls.dragging) {
          transformControls.detach();
        }
        return;
      }

      const obj = intersections[0].object;

      transformControls.attach(obj);

      const gizmo = transformControls.getHelper();
      scene.add(gizmo);
    }

    canvasRef.current.addEventListener("mousedown", handleCanvasClick);

    return () => {
      // Canvas reference is volatile.
      if (canvasRef.current) {
        canvasRef.current.removeEventListener("mousedown", handleCanvasClick);
      }
    };
  }, [scene, camera, transformControls]);

  return {
    isDraggingZener,
  };
}
