import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useMemo,
} from "react";
import { ColQueryOp, IrrUnit, RoomScan, Simulation } from "../../types";
import Axios, { CancelTokenSource } from "axios";
import { useSnackbar } from "notistack";
import { RoomScansNetUtils, SimulationsNetUtils } from "../../networking";
import HeatmapColorkey from "../../components/HeatmapColorkey";
import { useRenderer } from "../../hooks/threeJs/useRenderer";
import { useWindowResizeHandler } from "../../hooks/threeJs/useWindowResizeHandler";
import { useModelLoader } from "../../hooks/threeJs/useModelLoader";
import useDragZener from "../../hooks/threeJs/useDragZener";
import {
  getRoomDimension,
  vector3ToZenerPositions,
  createLUTTexture,
} from "../../utils/threejs";
import { useZenerCeilingPos } from "../../hooks/threeJs/useZenerCeilingPos";
import useIrrHeatmap from "../../hooks/threeJs/useIrrHeatmap";
import * as THREE from "three";
import { useQuery } from "@tanstack/react-query";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
} from "@mui/material";
import HeatmapTooltip from "../../components/HeatmapTooltip";
import SaveZenerPositionsModal from "../../components/SaveZenerPositionsModal";
import SimulationControls from "../../components/SimulationControls/SimulationControls";
import { MeshBVH } from "three-mesh-bvh";
import InfoIcon from "@mui/icons-material/Info";
import SimulationInfo from "../../components/SimulationInfo";

import "./SimulateDetails.scss";
import useGrid from "../../hooks/threeJs/useGrid";
import { calculateDutyCycle } from "../../lib/Akima";

function cancelRequest(cts: React.MutableRefObject<CancelTokenSource | null>) {
  if (cts.current !== null) {
    cts.current.cancel();
  }
  cts.current = Axios.CancelToken.source();
}

export default function SimulateDetails(): React.ReactElement {
  // State and Refs
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [roomScan, setRoomScan] = useState<RoomScan | null>(null);
  const [roomSize, setRoomSize] = useState<THREE.Vector3 | null>(null);
  const [modelUrl, setModelUrl] = useState<string | null>(null);
  const [simulation, setSimulation] = useState<Simulation | null>(null);
  const [simulationActive, setSimulationActive] = useState(false);
  const [tooltip, setTooltip] = useState(null);
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const [saveModalVisible, setSaveModalVisible] = useState(false);
  const [videoUrl, setVideoUrl] = useState<string | null>(null);
  const [showFoV, setShowFoV] = useState(true);
  const [showRoomInfo, setShowRoomInfo] = useState(false);

  const [expTime, setExpTime] = useState(null);
  const [selectedUnit, setSelectedUnit] = useState<IrrUnit>(IrrUnit.WPerMsq);

  const { enqueueSnackbar } = useSnackbar();
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const insertSimulationCTSRef = useRef<CancelTokenSource | null>(null);

  const renderCtx = useRenderer(canvasRef);
  const { scene, camera, renderer } = renderCtx;

  const gridCtx = useGrid(renderCtx, roomSize);
  const { gridVisible, setGridVisible } = gridCtx;

  useModelLoader(renderCtx, modelUrl, roomSize);
  useWindowResizeHandler(canvasRef, camera, renderer);

  // Hook to manage Zener positions
  const {
    zenerPositions,
    setZenerPositions,
    beginAddZener,
    removeZener,
    addZener,
    ghostZener,
  } = useZenerCeilingPos(
    renderCtx,
    gridCtx,
    roomSize,
    canvasRef,
    showFoV,
    setTooltip,
  );

  const { isDraggingZener } = useDragZener(
    canvasRef,
    renderCtx,
    zenerPositions,
    setZenerPositions,
  );

  const showIrradiance = Boolean(simulationActive && zenerPositions);

  const { irrRange, planeIrrRange } = useIrrHeatmap(
    canvasRef,
    renderCtx,
    zenerPositions,
    setTooltip,
    showIrradiance,
    isDraggingZener,
    expTime,
  );

  const queryParams = useMemo(
    () => new URLSearchParams(window.location.search),
    [window.location.search],
  );

  const roomScanQueryRes = useQuery({
    queryKey: [
      RoomScansNetUtils.GET_ROOM_SCANS_FILES_URL,
      queryParams.get("scan_id"),
    ],
    staleTime: Infinity,
    queryFn: ({ signal }) => {
      const scanId = queryParams.get("scan_id");

      if (!scanId) {
        return null;
      }

      return RoomScansNetUtils.getRoomScansFiles(
        { scan_id: { value: scanId, op: ColQueryOp.Eq } },
        signal,
      );
    },
  });

  const simulationQueryRes = useQuery({
    enabled: !!queryParams.get("simulation_id"),
    queryKey: [
      SimulationsNetUtils.QUERY_SIMULATIONS_URL,
      queryParams.get("simulation_id"),
    ],
    staleTime: Infinity,
    queryFn: ({ signal }) => {
      const simId = queryParams.get("simulation_id");

      if (!simId) {
        return null;
      }

      return SimulationsNetUtils.getSimulations(
        {
          simulation_id: { value: simId, op: ColQueryOp.Eq },
        },
        signal,
      );
    },
  });

  useEffect(() => {
    if (roomScanQueryRes.isError) {
      console.error(roomScanQueryRes.error);
      enqueueSnackbar("Failed to fetch room scan data.", {
        variant: "error",
      });
      return;
    }

    const fetchedRoomScans = roomScanQueryRes.data;
    if (!fetchedRoomScans) {
      return;
    }

    const fetchedRoomScan = fetchedRoomScans[0];

    if (
      !fetchedRoomScan?.files ||
      !fetchedRoomScan.name ||
      !fetchedRoomScan.s3_key
    ) {
      enqueueSnackbar("No room scan data found.", { variant: "warning" });
      return;
    }
    const { json, obj, mp4 } = fetchedRoomScan.files;
    if (!json || !obj || !mp4) {
      enqueueSnackbar("Incomplete room scan files.", {
        variant: "warning",
      });
      return;
    }

    setRoomScan(fetchedRoomScan);
    setRoomSize(getRoomDimension(JSON.parse(json)));
    setVideoUrl(mp4);

    const objBlob = new Blob([obj], { type: "text/plain" });
    setModelUrl(URL.createObjectURL(objBlob));
  }, [
    roomScanQueryRes.isError,
    roomScanQueryRes.data,
    roomScanQueryRes.error,
    enqueueSnackbar,
  ]);

  useEffect(() => {
    if (simulationQueryRes.isError) {
      console.error(
        "Failed to fetch simulation details:",
        simulationQueryRes.error,
      );
      enqueueSnackbar("Failed to fetch simulation data.", {
        variant: "error",
      });
      return;
    }

    const fetchedSimulations = simulationQueryRes.data;
    if (!fetchedSimulations) {
      return;
    }

    const fetchedSimulation = fetchedSimulations[0];
    setSimulation(fetchedSimulation);

    // Add zeners to the simulation
    const zenerPositions = fetchedSimulation.zener_positions.map(
      (pos) => new THREE.Vector3(pos.x, pos.y, pos.z),
    );
    zenerPositions.forEach((pos) => addZener(pos));
  }, [
    simulationQueryRes.data,
    simulationQueryRes.error,
    simulationQueryRes.isError,
    enqueueSnackbar,
  ]);

  const onPressSaveSimulation = () => {
    if (zenerPositions.length === 0) {
      enqueueSnackbar("Unable to save simulation data.", { variant: "error" });
      return;
    }

    setSaveModalVisible(true);
  };

  const saveSimulationAsync = async () => {
    cancelRequest(insertSimulationCTSRef);
    const devicePositions = vector3ToZenerPositions(zenerPositions);
    let isSuccess = true;
    const scan_id = new URLSearchParams(window.location.search).get("scan_id");
    if (!simulation?.name) {
      enqueueSnackbar("Please add a simulation name.", { variant: "error" });
      return;
    }
    if (!scan_id) {
      enqueueSnackbar("Scan ID is missing from the query params.", {
        variant: "error",
      });
      return;
    }

    try {
      await SimulationsNetUtils.insertSimulation(
        { ...simulation, zener_positions: devicePositions, scan_id },
        insertSimulationCTSRef.current,
      );
    } catch (error) {
      isSuccess = false;
      if (Axios.isCancel(error)) {
        console.log("Insert canceled by user");
      } else {
        console.error(error);
        enqueueSnackbar("Failed to insert saved simulation data.", {
          variant: "error",
        });
      }
    } finally {
      if (isSuccess) {
        setSaveModalVisible(false);
        enqueueSnackbar("Saved simulation data successfully.", {
          variant: "success",
        });
      }
    }
  };

  const updateSimulation = useCallback(async () => {
    if (!simulation) {
      enqueueSnackbar("No simulation data to update.", { variant: "error" });
      return;
    }
    const devicePositions = vector3ToZenerPositions(zenerPositions);
    const updatePayload = {
      name: simulation.name,
      review_status: simulation.review_status,
      zener_positions: devicePositions,
    };
    try {
      await SimulationsNetUtils.postSimulation(
        simulation.simulation_id,
        updatePayload,
      );
      enqueueSnackbar("Simulation updated successfully!", {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar("Failed to update simulation.", { variant: "error" });
      console.error("Simulation update error:", error);
    }
  }, [simulation, enqueueSnackbar, zenerPositions]);

  const toggleFoV = useCallback(() => {
    const nextShowFov = !showFoV;

    setShowFoV(nextShowFov);

    const fovGroup = scene.getObjectByName("ZenerFovs");
    if (fovGroup) {
      fovGroup.visible = nextShowFov;
    }
  }, [scene, showFoV]);

  const handleSimulation = async () => {
    setSimulationActive((p) => !p);
  };

  const handleExposureTime = (time: number) => {
    setExpTime(time);
  };

  function addTestPlane(testPlaneHeight: number) {
    if (!scene || !roomSize) {
      return;
    }

    const roomModel = scene.getObjectByName("RoomModel");
    const floorModel = roomModel?.getObjectByName("Floor0");

    if (!floorModel) {
      return;
    }

    const clonedFloorModel = floorModel.clone();
    clonedFloorModel.geometry = floorModel.geometry;
    clonedFloorModel.name = "createFloorMesh";

    clonedFloorModel.material = new THREE.MeshBasicMaterial({
      color: 0xf5f5dc,
      opacity: 1,
      transparent: false,
    });

    clonedFloorModel.position.set(
      floorModel.position.x,
      floorModel.position.z + testPlaneHeight,
      floorModel.position.y / 2,
    );
    roomModel.userData.uvxRoomModelUpdatedAt = Date.now();

    // Rebuild BVH tree - needs to update scene
    clonedFloorModel.geometry.boundsTree = new MeshBVH(
      clonedFloorModel.geometry,
    );

    roomModel.add(clonedFloorModel);
  }

  function removeTestPlane() {
    if (!scene) {
      return;
    }

    const clonedFloorModel = scene.getObjectByName("createFloorMesh");
    if (clonedFloorModel) {
      clonedFloorModel.removeFromParent();
      if (clonedFloorModel instanceof THREE.Mesh) {
        clonedFloorModel.geometry.dispose();
        if (Array.isArray(clonedFloorModel.material)) {
          clonedFloorModel.material.forEach((mat) => mat.dispose());
        } else {
          if (clonedFloorModel.material) {
            clonedFloorModel.material.dispose();
          }
        }
      }

      const roomModel = scene.getObjectByName("RoomModel");
      if (roomModel) {
        roomModel.userData.uvxRoomModelUpdatedAt = Date.now();
      }
    }
  }

  function handlePlaneHeight(nextPlaneHeight: number | null) {
    if (nextPlaneHeight === null) {
      removeTestPlane();
    } else {
      addTestPlane(nextPlaneHeight);
    }
  }

  // Tooltip is disabled when the mouse is out of the canvas
  useEffect(() => {
    const handleMouseMove = (event: MouseEvent) => {
      const canvas = canvasRef.current;
      if (canvas) {
        const rect = canvas.getBoundingClientRect();
        const isInsideCanvas =
          event.clientX >= rect.left &&
          event.clientX <= rect.right &&
          event.clientY >= rect.top &&
          event.clientY <= rect.bottom;

        if (isInsideCanvas) {
          setTooltipVisible(true);
        } else {
          setTooltipVisible(false);
        }
      }
    };

    window.addEventListener("mousemove", handleMouseMove);
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  useEffect(() => {
    if (zenerPositions.length === 0) {
      setSimulationActive(false);
    }
  }, [zenerPositions]);

  const handleUnitChange = (unit: IrrUnit) => {
    setSelectedUnit(unit);
  };

  const handleRoomInfoOpen = () => setShowRoomInfo(true);
  const handleRoomInfoClose = () => setShowRoomInfo(false);

  const loading = roomScanQueryRes.isLoading || simulationQueryRes.isLoading;

  const dutyCycle: number | null = useMemo(() => {
    if (!roomSize?.z) {
      return null;
    }

    return calculateDutyCycle(roomSize.z).dutyCycle;
  }, [roomSize]);

  return (
    <Grid
      container
      spacing={2}
      className="simulate-details-container"
      sx={{ p: 2 }}
    >
      {/* Main Canvas Area */}
      <Grid item xs={12} md={8}>
        <Box
          sx={{
            position: "relative",
          }}
        >
          {loading ? (
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              {/* Info */}
              <IconButton className="icon" onClick={handleRoomInfoOpen}>
                <InfoIcon />
              </IconButton>
            </>
          )}

          <canvas
            ref={canvasRef}
            className="heatmap-canvas"
            style={{
              visibility: loading ? "hidden" : "visible",
            }}
          />

          {/* Slide Toggle Button */}
          <Box
            sx={{
              position: "absolute",
              top: 16,
              left: 16,
              zIndex: 10,
              borderRadius: "8px",
              padding: "8px",
              visibility: loading ? "hidden" : "visible",
            }}
          >
            <FormControlLabel
              control={
                <Switch
                  checked={showFoV}
                  onChange={toggleFoV}
                  color="primary"
                />
              }
              label={showFoV ? "Show FoV" : "Hide FoV"}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={gridVisible}
                  onChange={(e) => setGridVisible(e.currentTarget.checked)}
                  color="secondary"
                />
              }
              label={gridVisible ? "Show Grid" : "Hide Grid"}
            />
          </Box>

          <HeatmapTooltip
            tooltip={tooltip}
            visible={tooltipVisible && simulationActive && !isDraggingZener}
            selectedUnit={selectedUnit}
            dutyCycle={dutyCycle}
          />

          {!!irrRange && (
            <HeatmapColorkey
              irrRange={irrRange}
              lutTexture={createLUTTexture(200)}
            />
          )}
        </Box>
      </Grid>

      {/* Side Panel */}
      <Grid item xs={12} md={4}>
        {/* Simulation Controls */}
        <SimulationControls
          zenerPositions={zenerPositions}
          simulationActive={simulationActive}
          ghostZener={ghostZener}
          beginAddZener={beginAddZener}
          removeZener={removeZener}
          handleSimulation={handleSimulation}
          onPressSaveSimulation={onPressSaveSimulation}
          handleExpTime={handleExposureTime}
          onPlaneHeightChange={handlePlaneHeight}
          planeIrrRange={planeIrrRange}
          height={roomSize?.z}
          onUnitChange={handleUnitChange}
        />
      </Grid>

      {/* Modal for Room Details and Video */}
      <Dialog
        open={showRoomInfo}
        onClose={handleRoomInfoClose}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Room Information</DialogTitle>
        <DialogContent dividers>
          <SimulationInfo roomSize={roomSize} videoUrl={videoUrl} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRoomInfoClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <SaveZenerPositionsModal
        visible={saveModalVisible}
        setVisible={setSaveModalVisible}
        simulation={simulation}
        handleSimulationUpdate={(updatedSimulation) =>
          setSimulation(updatedSimulation)
        } // Update simulation in parent
        saveChanges={saveSimulationAsync}
        updateChanges={updateSimulation}
      />
    </Grid>
  );
}
