import React from "react";
import { Routes, Route, Link, useLocation } from "react-router-dom";
import classnames from "classnames";
import withAuth from "../../hocs/withAuth";

import FleetOverview from "../FleetOverview";
import Coredumps from "../Coredumps";
import DeviceDetails from "../DeviceDetails";
import Ota from "../Ota";

import "./App.scss";
import OtaJobs from "../OtaJobs";
import UserGroups from "../UserGroups";
import Simulate from "../Simulate/Simulate";
import SimulateDetails from "../SimulateDetails";

export enum PageUrl {
  // eslint-disable-next-line @typescript-eslint/no-shadow
  FleetOverview = "/",
  // eslint-disable-next-line @typescript-eslint/no-shadow
  UserGroups = "/user-groups",
  // eslint-disable-next-line @typescript-eslint/no-shadow
  Coredumps = "/coredumps",
  // eslint-disable-next-line @typescript-eslint/no-shadow
  DeviceDetails = "/device-details",
  // eslint-disable-next-line @typescript-eslint/no-shadow
  Ota = "/ota",
  // eslint-disable-next-line @typescript-eslint/no-shadow
  Simulate = "/simulate",
  // eslint-disable-next-line @typescript-eslint/no-shadow
  SimulateDetails = "/simulate-details",
   // eslint-disable-next-line @typescript-eslint/no-shadow
  OtaJobs = "/ota-jobs",
}

function Nav(): React.ReactElement {
  const location = useLocation();
  return (
    <div className="navbar">
      <h2 className="title">Fleet Manager</h2>
      <div className="nav-links">
        <Link
          className={classnames(
            "nav-link",
            location.pathname === PageUrl.FleetOverview && "active",
          )}
          to={PageUrl.FleetOverview}
        >
          Fleet Overview
        </Link>
        <Link
          className={classnames(
            "nav-link",
            location.pathname === PageUrl.UserGroups && "active",
          )}
          to={PageUrl.UserGroups}
        >
          User Groups
        </Link>
        <Link
          className={classnames(
            "nav-link",
            location.pathname === PageUrl.Ota && "active",
          )}
          to={PageUrl.Ota}
        >
          OTA
        </Link>
        <Link
          className={classnames(
            "nav-link",
            location.pathname === PageUrl.Coredumps && "active",
          )}
          to={PageUrl.Coredumps}
        >
          Coredumps
        </Link>
        <Link
          className={classnames(
            "nav-link",
            location.pathname === PageUrl.Simulate && "active",
          )}
          to={PageUrl.Simulate}
        >
          Simulate
        </Link>
      </div>
    </div>
  );
}

function App(): React.ReactElement {
  return (
    <div className="app">
      <Nav />
      <Routes>
        <Route path={PageUrl.FleetOverview} element={<FleetOverview />} />
        <Route path={PageUrl.UserGroups} element={<UserGroups />} />
        <Route path={PageUrl.Coredumps} element={<Coredumps />} />
        <Route path={PageUrl.DeviceDetails} element={<DeviceDetails />} />
        <Route path={PageUrl.Ota} element={<Ota />} />
        <Route path={PageUrl.Simulate} element={<Simulate />} />
        <Route path={PageUrl.SimulateDetails} element={<SimulateDetails />} />
        <Route path={PageUrl.OtaJobs} element={<OtaJobs />} />
      </Routes>
    </div>
  );
}

export default withAuth(App);
