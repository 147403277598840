import Axios, { AxiosResponse, CancelTokenSource } from "axios";
import axios from "./axios";
import { DbQuery, Simulation, ReviewStatus, ZenerPosition } from "../types";
import * as NetUtils from "./utils";

export const SIMULATIONS_URL = "/simulations";
export const QUERY_SIMULATIONS_URL = SIMULATIONS_URL + "/query";

export type GetSimulationsQuery = DbQuery<Simulation>;

// Fetch Simulations for a list of completed simulations with Zener coordinates
export async function getSimulations(
  query: GetSimulationsQuery,
  cancelTokenSourceOrSignal?: CancelTokenSource | AbortSignal,
  offset?: number,
  count?: number,
): Promise<Simulation[]> {
  try {
    const resp: AxiosResponse = await axios.post(QUERY_SIMULATIONS_URL, query, {
      params: {
        offset,
        count,
      },
      ...NetUtils.genAbortPayload(cancelTokenSourceOrSignal),
    });

    if (!Array.isArray(resp.data)) {
      console.warn(`${SIMULATIONS_URL} payload is not an array:`, resp.data);
      return [];
    }

    return resp.data as Simulation[];
  } catch (error) {
    {
      if (Axios.isCancel(error)) {
        return;
      }
      throw error;
    }
  }
}

export interface CreateSimulationPayload {
  name: string;
  zener_positions: Array<ZenerPosition>;
  scan_id: string;
  review_status: ReviewStatus;
}

// Save a simulation and send it to the database
export async function insertSimulation(
  simulation: CreateSimulationPayload,
  cancelTokenSourceOrSignal?: CancelTokenSource | AbortSignal,
) {
  try {
    const payload = {
      ...simulation,
    };

    await axios.put(SIMULATIONS_URL, payload, {
      ...NetUtils.genAbortPayload(cancelTokenSourceOrSignal),
    });
  } catch (error) {
    if (Axios.isCancel(error)) {
      return;
    }
    throw error;
  }
}

export async function deleteSimulations(
  simulationIds: string[],
  cancelTokenSourceOrSignal?: CancelTokenSource | AbortSignal,
) {
  try {
    const payload = {
      simulation_ids: simulationIds,
    };

    await axios.delete(SIMULATIONS_URL, {
      ...NetUtils.genAbortPayload(cancelTokenSourceOrSignal),
      data: payload,
    });
  } catch (error) {
    if (Axios.isCancel(error)) {
      return;
    }
    throw error;
  }
}

export async function postSimulation(
  simulationId: string,
  updatedSimulation?: Partial<Simulation>,
  cancelTokenSourceOrSignal?: CancelTokenSource | AbortSignal,
): Promise<AxiosResponse | null> {
  if (!simulationId) {
    throw new Error("Simulation ID is required for update");
  }

  try {
    const payload = {
      simulation_id: simulationId,
      ...updatedSimulation,
    };

    return await axios.post(SIMULATIONS_URL, payload, {
      ...NetUtils.genAbortPayload(cancelTokenSourceOrSignal),
    });
  } catch (error) {
    if (Axios.isCancel(error)) {
      return null;
    }

    console.error("Failed to post simulation:", error);
    throw error;
  }
}
