import * as THREE from "three";
import { IrrUnit } from "../types";
import { calcWeightedIrr } from "../lib/Akima";

export const getRoomDimension = (roomData) => {
  // Use polygon data for the floor
  const polygonCorners = roomData.floors[0].polygonCorners;

  if (!polygonCorners || polygonCorners.length === 0) {
    console.error("No polygon corners found for the floor.");
    return { x: 0, y: 0, z: 0 };
  }

  const xValues = polygonCorners.map((corner) => corner[0]);
  const yValues = polygonCorners.map((corner) => corner[1]);

  const minX = Math.min(...xValues);
  const maxX = Math.max(...xValues);
  const minY = Math.min(...yValues);
  const maxY = Math.max(...yValues);

  const roomWidth = maxX - minX; // width
  const roomDepth = maxY - minY; // depth

  const walls = roomData.walls;
  // FIXME: Need to get max wall height.
  const roomHeight = walls.length > 0 ? walls[0].dimensions[1] : 0; // Z-axis is height

  return {
    x: Math.abs(roomWidth), // Width
    y: Math.abs(roomDepth), // Depth
    z: Math.abs(roomHeight), // Height
  };
};

export function createLUTTexture(maxIntensity = 200): THREE.DataTexture {
  const lutData = new Uint8Array(256 * 4); // RGBA format

  for (let i = 0; i < 256; i++) {
    const t = i / 255;
    let r, g, b;

    if (t < 0.25) {
      const blend = t / 0.25;
      r = 0;
      g = Math.floor(maxIntensity * blend);
      b = maxIntensity;
    } else if (t < 0.5) {
      const blend = (t - 0.25) / 0.25;
      r = 0;
      g = maxIntensity;
      b = Math.floor(maxIntensity * (1 - blend));
    } else if (t < 0.75) {
      const blend = (t - 0.5) / 0.25;
      r = Math.floor(maxIntensity * blend);
      g = maxIntensity;
      b = 0;
    } else {
      const blend = (t - 0.75) / 0.25;
      r = maxIntensity;
      g = Math.floor(maxIntensity * (1 - blend));
      b = 0;
    }

    lutData[i * 4] = r;
    lutData[i * 4 + 1] = g;
    lutData[i * 4 + 2] = b;
    lutData[i * 4 + 3] = 255; // Alpha channel
  }

  const lutTexture = new THREE.DataTexture(lutData, 256, 1, THREE.RGBAFormat);
  lutTexture.needsUpdate = true;
  return lutTexture;
}

export function createDirectionalLight(
  position: THREE.Vector3,
  targetPosition: THREE.Vector3,
  intensity = 1,
  shadowMapSize = 2048,
  shadowDistance = 100,
): THREE.DirectionalLight {
  const directionalLight = new THREE.DirectionalLight(0xffffff, intensity);

  directionalLight.position.copy(position);

  const target = new THREE.Object3D();
  target.position.copy(targetPosition);
  directionalLight.target = target;

  directionalLight.castShadow = true;

  directionalLight.shadow.mapSize.width = shadowMapSize;
  directionalLight.shadow.mapSize.height = shadowMapSize;

  const shadowCamera = directionalLight.shadow.camera;
  shadowCamera.near = 0.1;
  shadowCamera.far = shadowDistance;
  shadowCamera.left = -50;
  shadowCamera.right = 50;
  shadowCamera.top = 50;
  shadowCamera.bottom = -50;
  directionalLight.shadow.bias = -0.001;

  return directionalLight;
}

export function createAmbientLight(
  color: THREE.ColorRepresentation = 0xffffff,
  intensity: number = 1,
  name: string = "Ambient Light",
): THREE.AmbientLight {
  const ambientLight = new THREE.AmbientLight(color, intensity);
  ambientLight.name = name;

  return ambientLight;
}

export function createFovCone(position: THREE.Vector3): THREE.Mesh {
  const radius = position.z * (1 / Math.sqrt(3)); // tan(30deg) = o / h // h * tan(30deg) = o
  const height = position.z;
  const radialSegments = 32;

  const coneGeometry = new THREE.ConeGeometry(radius, height, radialSegments);
  const material = new THREE.MeshBasicMaterial({
    color: 0x04fcdc,
    transparent: true,
    opacity: 0.5,
    alphaHash: true,
  });
  const cone = new THREE.Mesh(coneGeometry, material);

  cone.name = "createZenerFov";
  cone.position.set(position.x, position.y, position.z / 2);
  cone.rotation.x = Math.PI / 2;

  return cone;
}

export function vector3ToZenerPositions(vectors: THREE.Vector3[]) {
  const positionArray = [];

  vectors.forEach((vector: THREE.Vector3) => {
    const position = {
      x: vector.x,
      y: vector.y,
      z: vector.z,
    };
    positionArray.push(position);
  });

  return positionArray;
}

export function generateRaysInConePointingDownWithAngle(
  fovAngle: number,
  rayCount: number,
): THREE.Vector3[] {
  const rays: THREE.Ray[] = [];
  const coneHalfAngle = fovAngle / 2;
  const thetaStep = (Math.PI * 2) / Math.ceil(Math.sqrt(rayCount)); // Azimuthal angle
  const phiStep = coneHalfAngle / Math.ceil(Math.sqrt(rayCount)); // Polar angle

  for (let phi = 0; phi <= coneHalfAngle; phi += phiStep) {
    for (let theta = 0; theta < Math.PI * 2; theta += thetaStep) {
      const x = Math.sin(phi) * Math.cos(theta);
      const y = Math.sin(phi) * Math.sin(theta);
      const z = Math.cos(phi);

      // Create a ray direction (normalized)
      const rayDir = new THREE.Vector3(x, y, -z).normalize();

      // Add the ray from the Zener position
      rays.push(rayDir);
    }
  }

  return rays;
}

export const isFloor = (object: THREE.Object3D): boolean => {
  const name = object.name?.toLowerCase();
  return name?.includes("floor") || false;
};

// Irradiance unit conversion
export const conversionFactors = {
  "W/m² / J/m²": 1,
  "W/cm² / J/cm²": 0.0001,
};

export function convertIrrValue(value: number | null, unit: IrrUnit) {
  return value !== null ? value * conversionFactors[unit] : null;
}

/**
 * Calculates the WEIGHTED irradiance between a Zener POINTING DOWN to a specified point.
 */
export function calcWeightedIrrFromCeiling(
  zener: THREE.Vector3,
  point: THREE.Vector3,
): number {
  const deltaVec = point.clone().sub(zener);
  const distM = deltaVec.length();
  const dir = deltaVec.normalize();
  const angleRad = dir.angleTo(new THREE.Vector3(0, 0, -1));

  return calcWeightedIrr(distM, angleRad);
}

export function clickRaycast(
  canvas: HTMLCanvasElement,
  scene: THREE.Scene,
  camera: THREE.PerspectiveCamera,
  evt: MouseEvent,
  targetGroup = scene,
  targetGroupName?: string,
): THREE.Object3D[] {
  const raycaster = new THREE.Raycaster();

  const rect = canvas.getBoundingClientRect();
  const mouse = new THREE.Vector2(
    ((evt.clientX - rect.left) / rect.width) * 2 - 1,
    -((evt.clientY - rect.top) / rect.height) * 2 + 1,
  );

  raycaster.setFromCamera(mouse, camera);

  if (targetGroupName !== undefined) {
    targetGroup = targetGroup.getObjectByName(targetGroupName);

    if (!targetGroup) {
      return [];
    }
  }

  return raycaster.intersectObject(targetGroup, true);
}
