import React, { useEffect, useState, useRef, useCallback } from "react";
import * as THREE from "three";
import { TrackballControls } from "three/examples/jsm/Addons.js";

const FOV = 75; // Field of view in degrees
const NEAR_CLIPPING_PLANE = 1; // Objects closer
const FAR_CLIPPING_PLANE = 1000; // Objects further
const AMBIENT_LIGHT_INTENSITY = 1.5;
const DIRECTIONAL_LIGHT_INTENSITY = 2;

export interface UseRendererRes {
  renderer: THREE.WebGLRenderer | null;
  scene: THREE.Scene | null;
  camera: THREE.PerspectiveCamera | null;
  controls: TrackballControls | null;
  render: () => void;
}

// Hook for rendering
export const useRenderer = (
  canvasRef: React.RefObject<HTMLCanvasElement>,
): UseRendererRes => {
  const [renderer, setRenderer] = useState<THREE.WebGLRenderer | null>(null);
  const [scene, setScene] = useState<THREE.Scene | null>(null);
  const [camera, setCamera] = useState<THREE.PerspectiveCamera | null>(null);
  const [controls, setControls] = useState<TrackballControls | null>(null);

  useEffect(() => {
    if (!canvasRef.current) {
      return;
    }

    const canvas = canvasRef.current;

    // Create scene.
    const newScene = new THREE.Scene();

    // Create camera.
    const newCamera = new THREE.PerspectiveCamera(
      FOV,
      canvas.clientWidth / canvas.clientHeight,
      NEAR_CLIPPING_PLANE,
      FAR_CLIPPING_PLANE,
    );

    // Create renderer.
    const newRenderer = new THREE.WebGLRenderer({ canvas });
    newRenderer.setSize(canvas.clientWidth, canvas.clientHeight);
    newRenderer.setClearColor(0xeeeeee, 1);

    // Create controls.
    const newControls = new TrackballControls(
      newCamera,
      newRenderer.domElement,
    );
    newControls.minDistance = 2;
    newControls.maxDistance = 20;
    newControls.enableDamping = true;
    newControls.dampingFactor = 0.25;
    newControls.rotateSpeed = 3;
    newControls.screenSpacePanning = false;
    newControls.maxPolarAngle = Math.PI / 2;
    newControls.update();

    // Create lighting.
    const ambientLight = new THREE.AmbientLight(
      0x404040,
      AMBIENT_LIGHT_INTENSITY,
    );
    const directionalLight = new THREE.DirectionalLight(
      0xffffff,
      DIRECTIONAL_LIGHT_INTENSITY,
    );
    directionalLight.position.set(1, 1, 1).normalize();
    newScene.add(ambientLight);
    newScene.add(directionalLight);

    setScene(newScene);
    setCamera(newCamera);
    setRenderer(newRenderer);
    setControls(newControls);

    return () => {
      newRenderer.dispose();
    };
  }, []);

  const animationThreadIdRef = useRef(0);

  const render = useCallback(() => {
    renderer.render(scene, camera);
  }, [scene, camera, renderer]);

  useEffect(() => {
    // Increment the animation ID each time when the useEffect is called
    animationThreadIdRef.current++;
    const threadId = animationThreadIdRef.current;

    const animate = () => {
      if (
        threadId == animationThreadIdRef.current &&
        renderer &&
        scene &&
        camera
      ) {
        controls.update();
        render();
        requestAnimationFrame(animate);
      }
    };

    animate();

    return () => {
      animationThreadIdRef.current++;
    };
  }, [render, controls]);

  return { renderer, render, scene, camera, controls };
};
