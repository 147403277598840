import React, {
  useEffect,
  useRef,
  useState,
} from "react";

import Axios, { CancelTokenSource } from "axios";
import { useSnackbar } from "notistack";

import "./OtaJobs.css";
import Jobs from "../../components/OtaJobs";
import { cancelOtaJob, getDevicesByJobId, getOtaJobCount, getOtaJobs } from "../../networking/admin";
import { OtaDevices, OtaJob as OtaJobType } from "../../types";
import OtaDevicesProgress from "../../components/OtaDevicesProgress";

export default function OtaJobs(): React.ReactElement {
  const [jobs, setJobs] = useState<OtaJobType[]>([]);
  const [rowsPerJobPage, setRowsPerJobPage] = useState<number>(10);
  const [jobPage, setJobPage] = useState<number>(0);
  const [devices, setDevices] = useState<OtaDevices[]>([]);
  const [jobsCount, setJobsCount] = useState<number>(0);

  const fetchJobCancelTokenSourceRef = useRef<CancelTokenSource| null> (null);
  const fetchDeviceCancelTokenSourceRef = useRef<CancelTokenSource| null> (null);
  const fetchJobsCountTokenSourceRef = useRef<CancelTokenSource| null> (null);
  const postJobCancelTokenSourceRef = useRef<CancelTokenSource| null>(null);

  const [otaJobTableLoading, setOtaJobTableLoading] = useState(true);
  const [devcieTableLoading, setDeviceTableLoading] = useState(false);

  const [isJobDetail, setIsJobDetail] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  function cancelRequest(
    cts: React.MutableRefObject<CancelTokenSource | null>
  ) {
    if (cts.current !== null) {
      cts.current.cancel();
    }
    cts.current = Axios.CancelToken.source();
  }

  async function fetchJobs() {
    cancelRequest(fetchJobCancelTokenSourceRef);
    setOtaJobTableLoading(true);
    try {
      const offset = jobPage * rowsPerJobPage;
      const nextJobs = await getOtaJobs(offset, rowsPerJobPage, fetchJobCancelTokenSourceRef.current);
      setJobs(nextJobs);
      setOtaJobTableLoading(false);
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error.message, { variant: "error" });
    }
  }

  async function fetchJobsCount () {
    cancelRequest(fetchJobsCountTokenSourceRef);
    try {
      const data  = await getOtaJobCount();
      setJobsCount(data);
    } catch (error) {
      console.error(error);
    }
  }

  async function handleCancelJob(jobId: string) { 
    cancelRequest(postJobCancelTokenSourceRef); 
    try {
      await cancelOtaJob(jobId,postJobCancelTokenSourceRef.current);
      enqueueSnackbar("Canceled job", { variant: "success" });
      fetchJobs();
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error.message, { variant: "error" });
    }
  }

  async function fetchDevices(jobId: string) {
    cancelRequest(fetchDeviceCancelTokenSourceRef);
    setDeviceTableLoading(true);
    setIsJobDetail(true);
    try {
      const otaDevices = await getDevicesByJobId(jobId, fetchDeviceCancelTokenSourceRef.current);
      setDevices(otaDevices);
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error.message, { variant: "error" });
    } finally {
      setDeviceTableLoading(false); 
    }
  }
  
  
  useEffect(() => {
    setIsJobDetail(false);
    fetchJobs();
    fetchJobsCount();
  }, [jobPage, rowsPerJobPage]);

  return (
    <div className="ota">
      <div className="ota-fleet-status-row">
        <Jobs
          jobs ={jobs}
          count={jobsCount}
          page={jobPage}
          setPage={setJobPage}
          rowsPerPage={rowsPerJobPage}
          setRowsPerPage={setRowsPerJobPage}
          onCancelJob={handleCancelJob}
          onGetDevicesByJobId={fetchDevices}
          loading={otaJobTableLoading}
        />
      </div>

      <div>
        {isJobDetail &&      
          <OtaDevicesProgress
            devicesProgresses={devices}
            loading={devcieTableLoading}
            deviceCount={devices?.length || 0}
          />
        }
      </div>
    </div>
  );
}
