import { CancelTokenSource, CancelToken } from "axios";

export interface AbortPayload {
  cancelToken?: CancelToken;
  signal?: AbortSignal;
}

export function genAbortPayload(
  cancelTokenSourceOrSignal?: CancelTokenSource | AbortSignal,
): AbortPayload {
  return {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    cancelToken: (cancelTokenSourceOrSignal as any)?.token,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    signal: ((cancelTokenSourceOrSignal as any)?.abort
      ? cancelTokenSourceOrSignal
      : undefined) as AbortSignal | undefined,
  };
}
