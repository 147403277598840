import React, { useEffect, useMemo, useRef, useState } from "react";
import { MaterialReactTable, MRT_PaginationState } from "material-react-table";
import Axios, { CancelTokenSource } from "axios";
import { useSnackbar } from "notistack";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link, useNavigate } from "react-router-dom";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { PageUrl } from "../App";

import { ColQueryOp } from "../../types";
import { formatDate } from "../../utils/util";
import "./Simulate.scss";
import {
  deleteSimulations,
  getSimulations,
} from "../../networking/simulations";
import { getRoomScans, getRoomScansCount } from "../../networking/room_scan";

function generateUrl(base: string, params: Record<string, string>): string {
  const queryString = new URLSearchParams(params).toString();
  return `${base}?${queryString}`;
}

export default function Simulate(): React.ReactElement {
  const [roomScans, setRoomScans] = useState([]);
  const [scansCount, setScansCount] = useState<number>(0);
  const [simulationsAvailability, setSimulationsAvailability] = useState<
    Record<string, boolean>
  >({});
  const [loading, setLoading] = useState<boolean>(true);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const [openModal, setOpenModal] = useState(false);
  const [selectedSimulations, setSelectedSimulations] = useState([]);
  const [selectedScanId, setSelectedScanId] = useState<string | null>(null);

  const roomScansCTSRef = useRef<CancelTokenSource | null>(null);
  const scansCountCTSRef = useRef<CancelTokenSource | null>(null);
  const simulationsCTSRef = useRef<CancelTokenSource | null>(null);

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  function cancelRequest(cts: React.MutableRefObject<CancelTokenSource>) {
    if (cts.current !== null) {
      cts.current.cancel();
    }
    cts.current = Axios.CancelToken.source();
  }

  async function fetchRoomScans() {
    setLoading(true);
    cancelRequest(roomScansCTSRef);
    cancelRequest(scansCountCTSRef);
    try {
      const offset = pagination.pageIndex * pagination.pageSize;
      const nextRoomScans = await getRoomScans(
        undefined,
        roomScansCTSRef.current,
        offset,
        pagination.pageSize,
      );
      const nextRoomScansCount = await getRoomScansCount(
        undefined,
        scansCountCTSRef.current,
      );

      setRoomScans(nextRoomScans);
      setScansCount(nextRoomScansCount);

      if (!nextRoomScans) {
        return;
      }

      // Check simulation availability
      const simulationsAvailability = nextRoomScans.map(async (scan) => {
        const simulations = await getSimulations({
          scan_id: { value: scan.scan_id, op: ColQueryOp.Eq },
        });
        return {
          scan_id: scan.scan_id,
          hasSimulations: simulations.length > 0,
        };
      });

      const availabilityResults = await Promise.all(simulationsAvailability);
      const availabilityRes = availabilityResults.reduce(
        (acc, { scan_id, hasSimulations }) => ({
          ...acc,
          [scan_id]: hasSimulations,
        }),
        {},
      );
      setSimulationsAvailability(availabilityRes);
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error.message, { variant: "error" });
    } finally {
      setLoading(false);
    }
  }

  async function fetchSimulations(scanId: string) {
    setLoading(true);
    cancelRequest(simulationsCTSRef);
    try {
      const fetchedSimulations = await getSimulations(
        { scan_id: { value: scanId, op: ColQueryOp.Eq } },
        simulationsCTSRef.current,
      );
      setSelectedSimulations(fetchedSimulations);
      setOpenModal(true);
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Failed to fetch simulations.", { variant: "error" });
    } finally {
      setLoading(false);
    }
  }

  async function removeSimulation(simId: string, scanId: string) {
    setLoading(true);
    try {
      await deleteSimulations([simId]);

      // Refetch simulations for the update
      await fetchSimulations(scanId);

      enqueueSnackbar("Simulation deleted successfully.", {
        variant: "success",
      });
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Failed to delete simulation.", { variant: "error" });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchRoomScans();
  }, [pagination.pageIndex]);

  const columns = useMemo(
    () => [
      {
        header: "Scan ID",
        accessorKey: "scan_id",
        enableClickToCopy: true,
      },
      {
        header: "Owner ID",
        accessorKey: "owner_id",
        enableClickToCopy: true,
      },
      {
        header: "Name",
        accessorKey: "name",
        enableClickToCopy: true,
      },
      {
        header: "Upload Date",
        accessorKey: "upload_date",
        Cell: ({ cell }: { cell: { getValue } }) => formatDate(cell.getValue()),
      },
      {
        header: "Simulations",
        Cell: ({ row }) => {
          const hasSimulations = simulationsAvailability[row.original.scan_id];
          return (
            <Button
              variant="contained"
              size="small"
              disabled={!hasSimulations}
              onClick={() => {
                setSelectedScanId(row.original.scan_id);
                fetchSimulations(row.original.scan_id);
              }}
            >
              {hasSimulations ? "View" : "No Simulations"}
            </Button>
          );
        },
      },
    ],
    [simulationsAvailability],
  );

  return (
    <div>
      <MaterialReactTable
        columns={columns}
        data={roomScans ?? []}
        initialState={{ showColumnFilters: true }}
        manualPagination
        onPaginationChange={setPagination}
        rowCount={scansCount}
        state={{
          isLoading: loading,
          pagination,
        }}
        enableRowActions
        renderRowActions={({ row }) => (
          <Box sx={{ display: "flex", gap: "1rem" }}>
            <Link
              to={generateUrl(PageUrl.SimulateDetails, {
                scan_id: row.getValue<string>("scan_id"),
              })}
              target="_blank"
            >
              <OpenInNewIcon />
            </Link>
          </Box>
        )}
      />

      <Dialog
        open={openModal}
        onClose={() => setOpenModal(false)}
        fullWidth
        maxWidth="xl"
      >
        <DialogTitle>Simulations for Scan ID - {selectedScanId}</DialogTitle>
        <DialogContent>
          {loading ? (
            <Typography>Loading simulations...</Typography>
          ) : selectedSimulations.length > 0 ? (
            <Table>
              <TableHead>
                <TableRow className="simulations-table-row">
                  <TableCell className="simulations-table-cell">ID</TableCell>
                  <TableCell className="simulations-table-cell">Name</TableCell>
                  <TableCell className="simulations-table-cell">
                    Total Zener
                  </TableCell>
                  <TableCell className="simulations-table-cell">
                    Upload Date
                  </TableCell>
                  <TableCell className="simulations-table-cell">
                    Status
                  </TableCell>
                  <TableCell className="simulations-table-cell">
                    Actions
                  </TableCell>
                  <TableCell className="simulations-table-cell" />
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedSimulations?.map((simulation) => (
                  <TableRow
                    key={simulation.simulation_id}
                    className="simulations-table-row"
                  >
                    <TableCell className="simulations-id">
                      {simulation.simulation_id}
                    </TableCell>
                    <TableCell className="simulations-table-cell">
                      {simulation.name}
                    </TableCell>
                    <TableCell className="simulations-zener-position">
                      {simulation?.zener_positions.length}{" "}
                      {simulation?.zener_positions === 1 ? "Zener" : "Zeners"}
                    </TableCell>
                    <TableCell className="simulations-table-cell">
                      {formatDate(simulation.upload_date)}
                    </TableCell>
                    <TableCell className="simulations-table-cell">
                      {simulation.review_status}
                    </TableCell>
                    <TableCell className="simulations-table-cell simulations-table-cell-actions">
                      <Button
                        variant="contained"
                        size="small"
                        onClick={() =>
                          navigate(
                            generateUrl(PageUrl.SimulateDetails, {
                              scan_id: simulation.scan_id,
                              simulation_id: simulation.simulation_id,
                            }),
                          )
                        }
                      >
                        Simulation
                      </Button>
                    </TableCell>

                    <TableCell className="simulations-table-cell">
                      <IconButton
                        size="small"
                        sx={{ ml: 1 }}
                        onClick={() =>
                          removeSimulation(
                            simulation.simulation_id,
                            selectedScanId,
                          )
                        }
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell
                    colSpan={7}
                    align="center"
                    className="simulations-table-row"
                  >
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={() =>
                        navigate(
                          generateUrl(PageUrl.SimulateDetails, {
                            scan_id: selectedScanId,
                          }),
                        )
                      }
                    >
                      Add New Simulation
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          ) : (
            <Typography>No simulations available.</Typography>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}
