import React, { useState } from "react";
import {
  Box,
  InputAdornment,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Tooltip,
} from "@mui/material";
import "./TimeInput.scss";
import { Time } from "../../types";

interface TimeInputProps {
  onTimeChange: (timeInSeconds: number) => void;
}

export default function TimeInput({ onTimeChange }: TimeInputProps): React.ReactElement {
  const [timeUnit, setTimeUnit] = useState<Time>(Time.Seconds);
  const [value, setValue] = useState<number | null>(null);

  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const numericValue = parseFloat(event.target.value);
    if (!isNaN(numericValue)) {
      setValue(numericValue);
      const timeInSeconds = converToSec(numericValue, timeUnit);
      onTimeChange(timeInSeconds);
    } else {
      setValue(null);
      onTimeChange(0);
    }
  };

  const handleUnitChange = (event: SelectChangeEvent<string>) => {
    const newUnit = event.target.value as Time;
    setTimeUnit(newUnit);
    if (value !== null) {
      const timeInSeconds = converToSec(value, newUnit);
      onTimeChange(timeInSeconds);
    }
  };

  const converToSec = (value: number, unit: Time): number => {
    switch (unit) {
      case Time.Minutes:
        return value * 60;
      case Time.Hours:
        return value * 3600;
      default:
        return value;
    }
  };

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      className="input-box"
    >
      <Tooltip      
        title="Exposure time is required to calculate dose"
        placement="top-start"
      >
        <TextField
          id="time-input"
          label={
            <span className="label-text">
              Exposure Time
            </span>
          }
          type="text"
          variant="outlined"
          value={value ?? ""}
          onChange={handleValueChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" className="input-container">
                <Select
                  value={timeUnit}
                  onChange={handleUnitChange}
                  className="select-input"
                  sx={{
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "transparent",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "transparent",
                    },
                    minWidth: "10px",
                    fontSize: "12px",
                  }}
                >
                  <MenuItem value={Time.Seconds}>Sec</MenuItem>
                  <MenuItem value={Time.Minutes}>Min</MenuItem>
                  <MenuItem value={Time.Hours}>Hour</MenuItem>
                </Select>
              </InputAdornment>
            ),
          }}
        />
      </Tooltip>
    </Box>
  );
}
