import React, { useMemo, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { OtaJob } from "../../types";
import { Menu, MenuItem, TableSortLabel } from "@mui/material";
import Loading from "../Loading";
import "./OtaJobs.css";
import { useNavigate } from "react-router-dom";
import { PageUrl } from "../../pages/App";

export interface OtaJobsProps {
  jobs: OtaJob[];
  count: number;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  rowsPerPage: number;
  setRowsPerPage: React.Dispatch<React.SetStateAction<number>>;
  onCancelJob?: (jobId: string) => void;
  loading: boolean;
  onGetDevicesByJobId?: (jobId: string) => void;
  isSimple?: boolean; // prop to control the simple view
}

export default function Jobs(props: OtaJobsProps): React.ReactElement {
  const {
    jobs,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    count,
    loading,
    onGetDevicesByJobId,
    onCancelJob,
    isSimple = false,
  } = props;

  const [isCancelMenuOpen, setIsCancelMenuOpen] = useState<boolean>(false);
  const [selectedJobId, setSelectedJobId] = useState<string | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [sortBy, setSortBy] = useState<keyof OtaJob | null>();
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");

  const navigate = useNavigate();

  const handleToggleMenu = (
    event: React.MouseEvent<HTMLButtonElement>,
    jobId: string
  ) => {
    setSelectedJobId(jobId);
    setAnchorEl(event.currentTarget);
    setIsCancelMenuOpen((prevOpen) => !prevOpen);
  };

  const handleCloseMenu = () => {
    setIsCancelMenuOpen(false);
    setAnchorEl(null);
  };

  const handleCancelJob = () => {
    if (!selectedJobId) {
      return;
    }
    onCancelJob?.(selectedJobId);
    handleCloseMenu();
  };

  const handleSort = (column: keyof OtaJob) => {
    const isAsc = sortBy === column && sortOrder === "asc";
    setSortOrder(isAsc ? "desc" : "asc");
    setSortBy(column);
  };

  const sortedJobs = useMemo(() => {
    if (!sortBy) { 
      return jobs; 
    }

    return [...jobs].sort((a, b) => {
      const aValue = a[sortBy];
      const bValue = b[sortBy];

      if (aValue < bValue) { 
        return sortOrder === "asc" ? -1 : 1; 
      }

      if (aValue > bValue) { 
        return sortOrder === "asc" ? 1 : -1; 
      }

      return 0;
    });
  }, [jobs, sortBy, sortOrder]);

  const onClickJob = (jobId: string) => {
    if (!isSimple && onGetDevicesByJobId) {
      onGetDevicesByJobId(jobId);
      setSelectedJobId(jobId);
    } else {
      navigate(PageUrl.OtaJobs); // Navigates to the OtaJobs page
    }
  };

  const statusClass = (status: string) => {
    return (status || "default").toLowerCase();
  };
  

  return (
    <>
      <TableContainer component={Paper}>
        <Loading loading={loading}>
          <Table className="fw-version-hist-table">
            <TableHead>
              <TableRow>
                <TableCell>Job Id</TableCell>
                {!isSimple && (
                  <TableCell>
                    Firmware Version
                  </TableCell>
                )}
                <TableCell>
                  <TableSortLabel
                    active={sortBy === "status"}
                    direction={sortBy === "status" ? sortOrder : "asc"}
                    onClick={() => handleSort("status")}
                  >
                    Status
                  </TableSortLabel>
                </TableCell>

                <TableCell>
                  <TableSortLabel
                    active={sortBy === "job_start_ts"}
                    direction={sortBy === "job_start_ts" ? sortOrder : "asc"}
                    onClick={() => handleSort("job_start_ts")}
                  >
                    Job Started
                  </TableSortLabel>
                </TableCell>
                {!isSimple && (
                  <TableCell classes={{ root: "MuiMenu-root" }} />
                )}
              </TableRow>
            </TableHead>

            <TableBody>
              {sortedJobs?.map((row: OtaJob, i) => (
                <TableRow
                  key={i}
                  className={`table-row ${row.job_id === selectedJobId ? "selected-row" : ""}`}
                >
                  <TableCell
                    className="job-id"
                    onClick={() => {
                      onClickJob(row.job_id);
                    }}
                  >
                    {row.job_id}
                  </TableCell>
                  {!isSimple && (
                    <TableCell>
                      {row.firmware_version}
                    </TableCell>
                  )}
                  <TableCell className={statusClass(row.status)}>
                    {row.status}
                  </TableCell>
                  <TableCell>
                    {row.job_start_ts.toLocaleString()}
                  </TableCell>
                  {!isSimple && (
                    <TableCell
                      classes={{ root: "menu-col" }}
                      aria-haspopup="true"
                    >
                      {row.status === "IN_PROGRESS" && (
                        <IconButton
                          onClick={(event) => handleToggleMenu(event, row.job_id)}
                        >
                          <MoreVertIcon />
                        </IconButton>
                      )}
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(_, next: number) => setPage(next)}
            onRowsPerPageChange={(
              evt: React.ChangeEvent<HTMLInputElement>
            ) => setRowsPerPage(Number.parseInt(evt.target.value, 10))}
          />

          <Menu
            id="menu"
            anchorEl={anchorEl}
            keepMounted
            open={isCancelMenuOpen}
            onClose={handleCloseMenu}
          >
            <MenuItem onClick={handleCancelJob}>Cancel</MenuItem>
          </Menu>
        </Loading>
      </TableContainer>
    </>
  );
}
