import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { Amplify } from "aws-amplify";
import { Authenticator } from "@aws-amplify/ui-react";
import { SnackbarProvider } from "notistack";
import App from "./pages/App";
import reportWebVitals from "./reportWebVitals";
import awsconfig from "./aws-exports";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import "./index.css";
// eslint-disable-next-line import/no-unresolved
import "@aws-amplify/ui-react/styles.css"; // default th

Amplify.configure(awsconfig);

const queryClient = new QueryClient();

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <Authenticator.Provider>
      <Router>
        <QueryClientProvider client={queryClient}>
          <SnackbarProvider maxSnack={3}>
            <App />
            <ReactQueryDevtools initialIsOpen={false} />
          </SnackbarProvider>
        </QueryClientProvider>
      </Router>
    </Authenticator.Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
