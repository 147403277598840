import React from "react";
import { CardContent, Typography, Box } from "@mui/material";
import ReactPlayer from "react-player";

interface SimulationInfoProps {
  roomSize?: { x: number; y: number; z: number };
  videoUrl?: string | null;
}

const SimulationInfo: React.FC<SimulationInfoProps> = ({ roomSize, videoUrl }) => {
  return (
    <>
      {/* Room Details */}
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Room Details
        </Typography>
        {roomSize ? (
          <Typography variant="body2">
            Room Dimensions: {roomSize.x.toFixed(2)}m (W) × {roomSize.y.toFixed(2)}m (D) ×{" "}
            {roomSize.z.toFixed(2)}m (H)
          </Typography>
        ) : (
          <Typography variant="body2" color="textSecondary">
            Room size information unavailable.
          </Typography>
        )}
      </CardContent>

      {/* Video */}
      {videoUrl && (
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Room Scan Video
          </Typography>
          <Box sx={{ mt: 2 }}>
            <ReactPlayer url={videoUrl} controls playing width="100%" />
          </Box>
        </CardContent>
      )}
    </>
  );
};

export default SimulationInfo;
