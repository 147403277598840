import { useEffect, useState } from "react";
import * as THREE from "three";
import { UseRendererRes } from "./useRenderer";

const GRID_SIZE = 0.4;

export function snapToGrid(pos: number): number {
  return Math.round(pos / GRID_SIZE) * GRID_SIZE;
}

export interface UseGridRes {
  gridVisible: boolean;
  setGridVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function useGrid(
  renderCtx: UseRendererRes,
  roomSize: THREE.Vector3 | null,
): UseGridRes {
  const [gridVisible, setGridVisible] = useState(false);

  useEffect(() => {
    if (!gridVisible) {
      return;
    }

    const maxDimLen = Math.max(roomSize.x, roomSize.y);
    const size = Math.ceil(maxDimLen / 0.4 + 1) * 0.4;
    const gridPosOffset =
      Math.round(size / GRID_SIZE) % 2 == 0 ? GRID_SIZE : GRID_SIZE / 2;

    const grid = new THREE.GridHelper(size, size / GRID_SIZE);
    grid.rotation.x = Math.PI / 2; // Rotate to lie on the ceiling

    grid.position.z = roomSize.z;
    grid.position.x = gridPosOffset;
    grid.position.y = gridPosOffset;

    renderCtx.scene.add(grid);

    return () => {
      renderCtx.scene.remove(grid);
      grid.dispose();
    };
  }, [renderCtx.scene, roomSize, gridVisible]);

  return {
    gridVisible,
    setGridVisible,
  };
}
