import React, { useMemo, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { OtaDevices, OtaEvt } from "../../types";
import { TableSortLabel } from "@mui/material";
import Loading from "../Loading";
import ProgressBar from "../ProgressBar";
import { BIN_CHUNK_LEN } from "../../constants";

export interface OtaDevicesProgressProps {
  devicesProgresses: OtaDevices[];
  deviceCount: number;
  loading: boolean;
}

export default function OtaDevicesProgress(props: OtaDevicesProgressProps): React.ReactElement {
  const { devicesProgresses, deviceCount, loading } = props;
  const [sortBy, setSortBy] = useState<keyof OtaDevices | null>(null);
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");

  function handleSort(column: keyof OtaDevices) {
    if (sortBy !== column) {
      // New column selected. Sort ascending by default.
      setSortOrder("asc");
    }

    setSortBy(column);
  };


  const sortedJobs = useMemo(() => {
    if (!sortBy) {
      return devicesProgresses;
    }

    return [...devicesProgresses].sort((a, b) => {
      const aValue = a[sortBy];
      const bValue = b[sortBy];

      if (aValue < bValue) {
        return sortOrder === "asc" ? -1 : 1;
      }
      if (aValue > bValue) {
        return sortOrder === "asc" ? 1 : -1;
      }

      return 0;
    });
  }, [devicesProgresses, sortBy, sortOrder]);

  function jobEvtToProgress(data: OtaDevices) {
    if (data.evt_type === OtaEvt.ChunkRequest) {
      const chunkSize = BIN_CHUNK_LEN;
      const totalNumChunks = Math.ceil(data.size / chunkSize);
      const currentChunkNumber = Math.floor(data.offset / chunkSize);

      if (currentChunkNumber < totalNumChunks) {
        return <ProgressBar totalSize={totalNumChunks} progressCount={currentChunkNumber} />;
      } else {
        return "Completed";
      }
    } else if (data.evt_type === OtaEvt.Cancelled) {
      return "Cancelled";
    } else if (data.evt_type === OtaEvt.Error) {
      return "Error";
    } else {
      return "Awaiting Chunk Request";
    }
  };



  if (deviceCount === 0) {
    return <h3>No OTA events</h3>;
  }

  return (
    <>
      <h3>Total device count: {deviceCount}</h3>
      <TableContainer component={Paper}>
        <Loading loading={loading}>
          <Table className="fw-version-hist-table">
            <TableHead>
              <TableRow>
                <TableCell>Device ID</TableCell>
                <TableCell align="center">Progress</TableCell>
                <TableCell align="right">
                  <TableSortLabel
                    active={sortBy === "latest_evt_ts"}
                    direction={sortBy === "latest_evt_ts" ? sortOrder : "asc"}
                    onClick={() => handleSort("latest_evt_ts")}
                  >
                    Last Updated
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedJobs.map((row: OtaDevices, i) => (
                <TableRow key={i}>
                  <TableCell>{row.device_id}</TableCell>
                  <TableCell align="center">{jobEvtToProgress(row)}</TableCell>
                  <TableCell align="right">{new Date(row.latest_evt_ts).toLocaleString()}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Loading>
      </TableContainer>
    </>
  );
}