import { useEffect, useState, useCallback } from "react";
import * as THREE from "three";
import { TooltipData } from "../../components/HeatmapTooltip";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader";
import { createFovCone } from "../../utils/threejs";
import { ZENER_HEIGHT } from "../../constants";
import ZenerModelStl from "../../assets/models/zener.stl";
import { RoomDimension } from "../../types";
import { UseRendererRes } from "./useRenderer";
import { UseGridRes, snapToGrid } from "./useGrid";

const tempZenerMaterial = new THREE.MeshPhongMaterial({
  color: 0x00b1e3, // Blue color for active Zener
  opacity: 0.5,
  transparent: true,
});

const fixedZenerMaterial = new THREE.MeshPhongMaterial({
  color: 0xffffff,
  opacity: 1,
  transparent: false,
});

export function checkIfZenerAboveFloor(
  floor: THREE.Object3D,
  zenerPos: THREE.Vector3,
): boolean {
  const downRay = new THREE.Raycaster(
    new THREE.Vector3(zenerPos.x, zenerPos.y, zenerPos.z),
    new THREE.Vector3(0, 0, -1),
  );

  const intersects = downRay.intersectObject(floor, true);
  return intersects.length > 0;
}

export const useZenerCeilingPos = (
  renderCtx: UseRendererRes,
  gridCtx: UseGridRes,
  roomSize: RoomDimension | null,
  canvas: React.RefObject<HTMLCanvasElement>,
  showFov: boolean,
  setTooltip: (tooltip: TooltipData | null) => void,
) => {
  const [zenerPositions, setZenerPositions] = useState<THREE.Vector3[]>([]);
  const [ghostZener, setGhostZener] = useState<THREE.Mesh | null>(null);

  const [zenerMesh, setZenerMesh] = useState<THREE.Mesh>(new THREE.Mesh());

  const zenerModelHeight = roomSize ? roomSize.z - ZENER_HEIGHT : 0;

  const { setGridVisible } = gridCtx;
  const { scene, camera, renderer } = renderCtx;

  async function loadZenerModel() {
    const loader = new STLLoader();
    await loader.load(
      ZenerModelStl,
      function (geometry) {
        const nextZenerMesh = new THREE.Mesh(geometry, tempZenerMaterial);
        nextZenerMesh.rotation.x = -Math.PI / 2;
        nextZenerMesh.name = "createZenerMesh";

        const boundingBox = new THREE.Box3().setFromObject(nextZenerMesh);
        const originalSize = boundingBox.getSize(new THREE.Vector3());
        const desiredSize = new THREE.Vector3(0.18, 0.18, ZENER_HEIGHT);
        const scaleFactor = desiredSize.clone().divide(originalSize);
        const uniformScale = Math.min(
          scaleFactor.x,
          scaleFactor.y,
          scaleFactor.z,
        );
        nextZenerMesh.scale.set(uniformScale, uniformScale, uniformScale);
        nextZenerMesh.userData = {};
        setZenerMesh(nextZenerMesh);
      },
      null,
      (error) => {
        console.log(error);
      },
    );
  }

  useEffect(() => {
    loadZenerModel();
  }, []);

  const addZener = useCallback(
    (position: THREE.Vector3) => {
      setZenerPositions((prev) => [...prev, position]);
    },
    [scene, zenerMesh],
  );

  const removeZener = useCallback(
    (index: number) => {
      setZenerPositions((prev) => prev.filter((_, i) => i !== index));
    },
    [zenerPositions],
  );

  const beginAddZener = useCallback(() => {
    if (!scene) {
      return;
    }

    const newGhostZener = zenerMesh.clone();
    newGhostZener.material = tempZenerMaterial;
    newGhostZener.position.set(0, 0, zenerModelHeight);
    newGhostZener.userData.isGhost = true;

    scene.add(newGhostZener);
    setGhostZener(newGhostZener);

    setGridVisible(true);
  }, [scene, setGridVisible, zenerMesh]);

  const commitGhostZener = useCallback(
    (position: THREE.Vector3) => {
      if (!ghostZener) {
        return;
      }

      addZener(position);

      // Remove ghost zener
      scene.remove(ghostZener);
      setGhostZener(null);
    },
    [ghostZener, addZener],
  );

  const removeAllZeners = useCallback(() => {
    setZenerPositions([]);
    setGhostZener(null);
    setGridVisible(false);
  }, []);

  // Sync Zeners in scene with Zener positions.
  useEffect(() => {
    if (!scene) {
      return;
    }

    let fovGroup = scene.getObjectByName("ZenerFovs");
    if (!fovGroup) {
      fovGroup = new THREE.Group();
      fovGroup.name = "ZenerFovs";
      fovGroup.visible = showFov;
      scene.add(fovGroup);
    }

    let zenerGroup = scene.getObjectByName("Zeners");
    if (!zenerGroup) {
      zenerGroup = new THREE.Group();
      zenerGroup.name = "Zeners";
      scene.add(zenerGroup);
    }

    // Add next Zeners.
    zenerPositions.forEach((pos: THREE.Vector3, i: number) => {
      // Create zener.
      const nextZener = zenerMesh.clone();
      nextZener.material = fixedZenerMaterial; // Use the white material for fixed Zener
      nextZener.position.copy(pos);
      zenerGroup.add(nextZener);

      // Create FoV cone.
      const fovCone = createFovCone(pos);
      if (!nextZener.userData) {
        nextZener.userData = {};
      }
      nextZener.userData.fovCone = fovCone;
      nextZener.userData.index = i;
      fovGroup.add(fovCone);
    });

    return () => {
      fovGroup.removeFromParent();
      zenerGroup.removeFromParent();
    };
  }, [zenerPositions, scene]);

  const handleMouseMove = useCallback(
    (event: MouseEvent) => {
      if (
        !camera ||
        !renderer ||
        !scene ||
        !roomSize ||
        !ghostZener ||
        !canvas.current
      ) {
        return;
      }
      const rect = canvas.current.getBoundingClientRect();
      // Adjust mouse coordinates to account for canvas offset
      const mouse = new THREE.Vector2(
        ((event.clientX - rect.left) / rect.width) * 2 - 1,
        -((event.clientY - rect.top) / rect.height) * 2 + 1,
      );

      const raycaster = new THREE.Raycaster();
      raycaster.setFromCamera(mouse, camera);

      // Define the ceiling plane with Z as height and Y as depth.
      // Plane pointing downward (Z is height).
      const ceilingPlane = new THREE.Plane(
        new THREE.Vector3(0, 0, -1),
        roomSize.z,
      );
      const intersectPoint = new THREE.Vector3();
      if (raycaster.ray.intersectPlane(ceilingPlane, intersectPoint)) {
        const snappedX = snapToGrid(intersectPoint.x);
        const snappedY = snapToGrid(intersectPoint.y);
        ghostZener.position.set(snappedX, snappedY, zenerModelHeight);
        renderer.render(scene, camera);
      }
    },
    [camera, renderer, scene, roomSize, ghostZener],
  );

  const handleDoubleClick = useCallback(
    (event: MouseEvent) => {
      if (!ghostZener || !roomSize || !scene) {
        return;
      }

      const floor = scene.getObjectByName("Floor0");
      if (!floor) {
        return;
      }

      const isAboveFloor = checkIfZenerAboveFloor(floor, ghostZener.position);

      if (isAboveFloor) {
        const fixedPosition = new THREE.Vector3(
          ghostZener.position.x,
          ghostZener.position.y,
          ghostZener.position.z,
        );
        commitGhostZener(fixedPosition);
      } else {
        const rect = canvas.current?.getBoundingClientRect();
        if (rect) {
          setTooltip({
            message: "You can place the Zener only on the ceiling.",
            x: event.clientX - rect.left,
            y: event.clientY - rect.top,
            pointData: null,
          });
          // Hide tooltip after 2 seconds
          setTimeout(() => setTooltip(null), 2000);
        }
      }
    },
    [commitGhostZener, scene, ghostZener, roomSize, canvas],
  );

  useEffect(() => {
    const handleClickBackspace = (event: MouseEvent | KeyboardEvent) => {
      if (event instanceof KeyboardEvent && event.key === "Backspace") {
        // Remove ghost Zener from scene.
        if (ghostZener) {
          scene.remove(ghostZener);
          setGhostZener(null);
        }
      }
    };

    document.addEventListener("keydown", handleClickBackspace);
    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("dblclick", handleDoubleClick);
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("dblclick", handleDoubleClick);
      document.removeEventListener("keydown", handleClickBackspace);
    };
  }, [handleMouseMove, handleDoubleClick, scene, ghostZener]);

  return {
    zenerPositions,
    setZenerPositions,
    beginAddZener,
    removeAllZeners,
    ghostZener,
    removeZener,
    addZener,
  };
};
