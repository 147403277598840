import React, { useCallback, useEffect, useMemo, useState } from "react";
import { AdminNetUtils } from "../../networking";
import Button from "@mui/material/Button";
import BoxModal from "../../components/BoxModal";
import { MaterialReactTable } from "material-react-table";
import { UserGroupWithInfo, UserGroup } from "../../networking/admin";
import "./UserGroups.scss";

export default function UserGroups(): React.ReactElement {
  const [userGroupList, setUserGroupList] = useState<UserGroupWithInfo[]>([]);
  const [loading, setLoading] = useState(false);
  const [warnModalOpen, setWarnModalOpen] = useState(false);

  const fetchUserGroups = useCallback(async () => {
    try {
      setLoading(true);
      const groups = await AdminNetUtils.getAllUserGroupsWithInfo();
      setUserGroupList(groups);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchUserGroups();
  }, []);

  const handleClick = useCallback((isLeaving: boolean, name: string) => {
    if (isLeaving && name === UserGroup.Uvx) {
      setWarnModalOpen(true);
      return;
    }

    isLeaving ? onClickLeave(name) : onClickJoin(name);
  }, [onClickJoin, onClickLeave]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "Group Name",
      },
      {
        accessorFn: (row) => row.isMember ? "Member" : "Not a member",
        header: "Member Status",
      },
      {
        accessorFn: (row) => row,
        header: "Join/Leave Group",
        Cell: ({ cell }) => {
          const { name, isMember } = cell.getValue();
          const text = isMember ? "Leave" : "Join";
          const color = isMember ? "error" : "primary";
          return (
            <Button onClick={() => handleClick(isMember, name)} color={color}>
              {text}
            </Button>
          );
        }
      }
    ],
    [handleClick],
  );

  async function onClickJoin(groupName: string) {
    try {
      await AdminNetUtils.addUserToGroup(groupName);
      await fetchUserGroups();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  async function onClickLeave(groupName: string) {
    try {
      await AdminNetUtils.removeUserFromGroup(groupName);
      await fetchUserGroups();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  function onClickLeaveAnyway() {
    setWarnModalOpen(false);
    onClickLeave(UserGroup.Uvx);
  }

  return (
    <div className="user-groups">
      <div className="table-container">
        <MaterialReactTable
          columns={columns}
          data={userGroupList}
          state={{
            isLoading: loading
          }}
        />
      </div>
      <BoxModal
        open={warnModalOpen}
        width={400}
        onClose={() => setWarnModalOpen(false)}
      >
        <div className="modal-text">
          {"Are you sure you want to leave the admin group \"UVX\"?"}
        </div>
        <div className="modal-text">
          {"You will lose all of your admin privileges. You must contact someone at UVX to regain them."}
        </div>
        <Button onClick={onClickLeaveAnyway} color="error">
          Leave Anyway
        </Button>
      </BoxModal>
    </div>
  );
}