import React from "react";
import { Modal, Box } from "@mui/material";

interface BoxModalProps {
  open: boolean;
  onClose: () => void;
  width: number;
  children: React.ReactElement | React.ReactElement[] | null;
}

export default function BoxModal(props: BoxModalProps): React.ReactElement {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: props.width,
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 24,
    p: 4,
  };

  return (
    <Modal
      open={props.open}
      onClose={props.onClose}
    >
      <Box sx={style}>
        {props.children}
      </Box>
    </Modal>
  );
}
