import Axios, { AxiosResponse, CancelTokenSource } from "axios";
import axios from "./axios";
import { DeviceMetadata, DbQuery } from "../types";
import * as Utils from "../utils/util";

const DEVICES_URL = "/devices";
const GET_DEVICES_URL = `${DEVICES_URL}/query`;
const GET_DEVICES_COUNT_URL = `${GET_DEVICES_URL}/count`;
const POST_DEVICES_SET_TAG_URL = `${DEVICES_URL}/tags/set`;
const POST_DEVICES_REMOVE_TAG_URL = `${DEVICES_URL}/tags/remove`;

export type GetDevicesQuery = DbQuery<DeviceMetadata>;

export async function getDevices(
  query: GetDevicesQuery,
  offset?: number,
  count?: number,
  cancelTokenSource?: CancelTokenSource,
): Promise<DeviceMetadata[]> {
  try {
    const resp: AxiosResponse = await axios.post(
      GET_DEVICES_URL,
      Utils.keysToSnakeCase(query),
      {
        params: {
          offset,
          count,
        },
        cancelToken: cancelTokenSource?.token,
      },
    );

    if (!Array.isArray(resp.data)) {
      // eslint-disable-next-line no-console
      console.warn(`${DEVICES_URL} payload not array: `, resp.data);
      return [];
    }

    const res: DeviceMetadata[] = Utils.keysToCamelCase(resp.data);
    return res;
  } catch (error) {
    if (Axios.isCancel(error)) {
      return [];
    }

    throw error;
  }
}

export async function getDevicesCount(
  query: GetDevicesQuery,
  cancelTokenSource?: CancelTokenSource,
): Promise<number> {
  const resp: AxiosResponse = await axios.post(
    GET_DEVICES_COUNT_URL,
    Utils.keysToSnakeCase(query),
    {
      cancelToken: cancelTokenSource?.token,
    },
  );

  const data = resp.data;

  if (data?.count === undefined || data?.count === null) {
    console.warn(`${GET_DEVICES_COUNT_URL} payload malformed: `, data);
    return null;
  }

  return data.count;
}

export async function deleteDevice(
  deviceId: string,
  cancelTokenSource?: CancelTokenSource,
): Promise<AxiosResponse | null> {
  try {
    const resp: AxiosResponse = await axios.delete(DEVICES_URL, {
      cancelToken: cancelTokenSource?.token,
      params: { device_id: deviceId },
    });
    return resp;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (Axios.isCancel(error) || error.response.status === 404) {
      return null;
    }

    throw error;
  }
}

export interface SetDeviceTagsParam {
  deviceId: string;
  tagIds: number[] | null;
}
export async function setDeviceTags(
  params: SetDeviceTagsParam[],
  cancelTokenSource?: CancelTokenSource,
): Promise<AxiosResponse | null> {
  try {
    const resp: AxiosResponse = await axios.post(
      POST_DEVICES_SET_TAG_URL,
      {
        tags: Utils.keysToSnakeCase(params),
      },
      {
        cancelToken: cancelTokenSource?.token,
      },
    );
    return resp;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (Axios.isCancel(error)) {
      return null;
    }

    throw error;
  }
}

export interface RemoveDeviceTagParam {
  deviceIds: string[];
  tagId: number;
}
export async function removeDeviceTags(
  params: RemoveDeviceTagParam[],
  cancelTokenSource?: CancelTokenSource,
): Promise<AxiosResponse | null> {
  try {
    const resp: AxiosResponse = await axios.post(
      POST_DEVICES_REMOVE_TAG_URL,
      {
        tags: Utils.keysToSnakeCase(params),
      },
      {
        cancelToken: cancelTokenSource?.token,
      },
    );
    return resp;
  } catch (error) {
    if (Axios.isCancel(error)) {
      return null;
    }

    throw error;
  }
}

export async function postDevice(
  deviceId: string,
  updatedDevice?: Partial<DeviceMetadata>,
  cancelTokenSource?: CancelTokenSource,
): Promise<AxiosResponse | null> {
  try {
    const payload = {
      device_id: deviceId,
      ...updatedDevice
    };

    return await axios.post(DEVICES_URL, payload, {
      cancelToken: cancelTokenSource?.token,
    });
  } catch (error) {
    if (Axios.isCancel(error)) {
      return null;
    }

    throw error;
  }
}

