import React, { useState } from "react";

import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useSnackbar } from "notistack";

import { DeviceMetadata } from "../../types";

import "./DeviceAttributeEditor.scss";
import { DeviceNetUtils } from "../../networking";

export interface DeviceAttributeEditorProps {
  device: DeviceMetadata;
  attribute: keyof Pick<DeviceMetadata, "name" | "height" | "description">;
}
export default function DeviceAttributeEditor(
  props: DeviceAttributeEditorProps,
) {
  const { device, attribute } = props;
  const inputType = attribute === "height" ? "number" : "string";
  function getLabel() {
    switch (attribute) {
      case "name":
        return "Name";
      case "description":
        return "Description";
      case "height": 
        return "Ceiling Height (m)";
    }
  }

  const [attributeValue, setAttributeValue] = useState<string>(String(device[attribute]));

  const { enqueueSnackbar } = useSnackbar();

  async function handleSubmit() {
    try {
      let formattedAttributeValue: DeviceMetadata[keyof DeviceMetadata] = attributeValue;
      if (attribute === "height") {
        formattedAttributeValue = Number.parseFloat(attributeValue);
        if (Number.isNaN(formattedAttributeValue)) {
          throw new Error("Invalid number");
        }
      }
      const updatedDeviceWithAttribute = { [attribute]: formattedAttributeValue };
      
      await DeviceNetUtils.postDevice(device.deviceId, updatedDeviceWithAttribute);

      enqueueSnackbar(`Updated ${attribute}`, { variant: "success" });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      console.error(error);
    }
  }

  return (
    <Paper className="device-attribute-editor">
      <div className="ceil-height-row">
        <TextField
          className="ceil-height-input"
          fullWidth
          type={inputType}
          label={getLabel()}
          value={attributeValue}
          onChange={(evt) => setAttributeValue(evt.target.value)}
          contentEditable={false}
        />
        <Button variant="contained" onClick={handleSubmit}>
          Update
        </Button>
      </div>
    </Paper>
  );
}
