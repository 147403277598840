import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  Divider,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import StopIcon from "@mui/icons-material/Stop";
import DeleteIcon from "@mui/icons-material/Delete";
import * as THREE from "three";
import { calculateDutyCycle } from "../../lib/Akima";
import TimeInput from "../TimeInput";
import { IrrUnit } from "../../types";
import { convertIrrValue } from "../../utils/threejs";
import { IrrRange } from "../../hooks/threeJs/useIrrHeatmap";

const EVAL_PLANE_HEIGHT_M = 1.9;

interface SimulationControlsProps {
  zenerPositions: THREE.Vector3[];
  simulationActive: boolean;
  ghostZener: THREE.Mesh | null;
  beginAddZener: () => void;
  removeZener: (index: number) => void;
  handleSimulation: () => void;
  onPressSaveSimulation: () => void;
  reviewStatus?: string;
  handleExpTime: (expTime: number) => void;
  onPlaneHeightChange: (planeHeight: number | null) => void;
  planeIrrRange: IrrRange | null;
  height: number;
  onUnitChange: (unit: IrrUnit) => void;
}

function mapReviewStatusToColor(
  reviewStatus: string,
): "warning" | "success" | "default" {
  switch (reviewStatus.toLowerCase()) {
    case "pending":
      return "warning";
    case "finished":
      return "success";
    default:
      return "default";
  }
}

export default function SimulationControls({
  zenerPositions,
  simulationActive,
  ghostZener,
  beginAddZener,
  removeZener,
  handleSimulation,
  onPressSaveSimulation,
  reviewStatus,
  handleExpTime,
  onPlaneHeightChange,
  planeIrrRange,
  height,
  onUnitChange,
}: SimulationControlsProps): React.ReactElement {
  const [exposureTime, setExposureTime] = useState<number | null>();
  const [planeHeightInput, setPlaneHeightInput] = useState<string>();
  const [planeHeight, setPlaneHeight] = useState<number | null>(null);
  const [selectedUnit, setSelectedUnit] = useState<IrrUnit>(IrrUnit.WPerMsq);
  const [loading, setLoading] = useState(false); // Loading state
  const [dutyCycleData, setDutyCycleData] = useState<{
    tBoost: number;
    tSettled: number;
    tLampOn: number;
    dutyCycle: number;
  } | null>(null);

  useEffect(() => {
    const nextDCData = calculateDutyCycle(height, EVAL_PLANE_HEIGHT_M);
    setDutyCycleData(nextDCData);
  }, [height]);

  useEffect(() => {
    if (exposureTime !== null) {
      setLoading(true);
      const timeout = setTimeout(() => {
        handleExpTime(exposureTime);
        setLoading(false);
      }, 500);

      return () => clearTimeout(timeout);
    }
  }, [exposureTime]);

  const handleExposureTimeChange = (timeInSeconds: number) => {
    setExposureTime(timeInSeconds);
    handleExpTime(timeInSeconds);
  };

  const handleUnitChange = (event: SelectChangeEvent<IrrUnit>) => {
    const newUnit = event.target.value as IrrUnit;
    setSelectedUnit(newUnit);
    onUnitChange(newUnit);
  };

  const renderResult = (label: string, value: number | null, type: string) => {
    if (value === null) return null;

    let formattedValue = "";
    let unit = "";

    switch (type) {
      case "irradiance":
        formattedValue =
          convertIrrValue(value, selectedUnit)?.toExponential(3) ?? "";
        unit = selectedUnit.startsWith("W/m²") ? "W/m²" : "W/cm²";
        break;
      case "dose":
        formattedValue = convertIrrValue(value, selectedUnit)?.toFixed(6) ?? "";
        unit = selectedUnit.startsWith("W/m²") ? "J/m²" : "J/cm²";
        break;
      default:
        formattedValue = value.toString();
    }
    return (
      <Typography variant="body2">
        {label}: {formattedValue} {unit}
      </Typography>
    );
  };

  function commitPlaneHeight() {
    const nextPlaneHeight = parseFloat(planeHeightInput);
    setPlaneHeight(Number.isNaN(nextPlaneHeight) ? null : nextPlaneHeight);
  }

  function clearPlaneHeight() {
    setPlaneHeight(null);
  }

  useEffect(() => {
    onPlaneHeightChange(planeHeight);
  }, [planeHeight]);

  const planeHeightApplied = planeHeight !== null;

  const showPlaneResults =
    simulationActive && !!planeIrrRange && planeHeightApplied;

  return (
    <Card sx={{ p: 2, boxShadow: 3 }}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Simulation Controls
        </Typography>

        {/* Simulation Status */}
        {reviewStatus && (
          <Box sx={{ mb: 2, display: "flex", alignItems: "center" }}>
            <Typography variant="subtitle1" sx={{ mr: 1 }}>
              Simulation Status:
            </Typography>
            <Chip
              label={reviewStatus}
              color={mapReviewStatusToColor(reviewStatus)}
              sx={{ fontWeight: "bold", fontSize: "0.9rem" }}
            />
          </Box>
        )}

        <Divider sx={{ my: 2 }} />

        {/* Zener Management */}
        <Box sx={{ mb: 3 }}>
          <Typography variant="subtitle1" gutterBottom>
            Zener Management
          </Typography>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="body2" sx={{ mb: 2 }}>
              {zenerPositions.length === 0
                ? "No Zeners added yet."
                : `Total Zeners: ${zenerPositions.length}`}
            </Typography>
            <Typography variant="body2">
              Duty Cycle: {(dutyCycleData?.dutyCycle * 100).toFixed(2)}%
            </Typography>
          </Box>
          {zenerPositions.length > 0 && (
            <Box sx={{ mt: 1, maxHeight: 150, overflowY: "auto" }}>
              {zenerPositions.map((position, index) => (
                <Box
                  key={index}
                  sx={{
                    mb: 1,
                    p: 1,
                    borderRadius: 1,
                    backgroundColor: "#f5f5f5",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box>
                    <Typography variant="body2" sx={{ fontWeight: 600 }}>
                      Zener {index + 1}
                    </Typography>
                    <Typography variant="body2">
                      X: {position.x.toFixed(2)}m, Y: {position.y.toFixed(2)}m,
                      Z: {position.z.toFixed(2)}m
                    </Typography>
                  </Box>
                  <IconButton
                    size="small"
                    color="error"
                    onClick={() => removeZener(index)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              ))}
            </Box>
          )}
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddCircleOutlineIcon />}
            onClick={beginAddZener}
            sx={{ mt: 2 }}
            disabled={!!ghostZener}
            fullWidth
          >
            Add Zener
          </Button>
        </Box>

        <Divider sx={{ my: 2 }} />

        {/* Simulation Controls */}
        <Box>
          <Typography variant="subtitle1" gutterBottom>
            Simulation
          </Typography>
          <Box sx={{ mb: 2, display: "flex", gap: 2, flexWrap: "wrap" }}>
            <Button
              variant="contained"
              color={simulationActive ? "error" : "primary"}
              startIcon={simulationActive ? <StopIcon /> : <PlayArrowIcon />}
              onClick={handleSimulation}
              disabled={zenerPositions.length === 0}
              fullWidth
            >
              {simulationActive ? "Stop Simulation" : "Start Simulation"}
            </Button>
            <Button
              variant="contained"
              color="success"
              startIcon={<PlayArrowIcon />}
              disabled={!simulationActive}
              onClick={onPressSaveSimulation}
              fullWidth
            >
              Save Simulation
            </Button>
          </Box>

          {/* Time Selector */}
          <Box
            sx={{ display: "grid", gridTemplateColumns: "4fr 3fr", gap: "4px" }}
          >
            <TimeInput onTimeChange={handleExposureTimeChange} />
            <Select
              value={selectedUnit}
              onChange={handleUnitChange}
              sx={{
                minWidth: 120,
              }}
            >
              <MenuItem value={IrrUnit.WPerMsq}>W/m² and J/m²</MenuItem>
              <MenuItem value={IrrUnit.WPerCMSq}>W/cm² and J/cm²</MenuItem>
            </Select>
          </Box>
        </Box>

        <Divider sx={{ my: 2 }} />
        <Box>
          <Typography variant="subtitle1" gutterBottom>
            Test Plane Settings
          </Typography>
          <Box sx={{ mt: 1, display: "flex", gap: 2, alignItems: "center" }}>
            <TextField
              type="number"
              label="Plane Height (m)"
              variant="outlined"
              value={planeHeightInput}
              onChange={(e) => setPlaneHeightInput(e.target.value)}
              disabled={planeHeightApplied}
              InputProps={{ inputProps: { step: "0.01", min: "0.1" } }}
            />
            {planeHeightApplied ? (
              <Button
                variant="contained"
                color="error"
                onClick={clearPlaneHeight}
              >
                Remove
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={commitPlaneHeight}
              >
                Apply
              </Button>
            )}
          </Box>
        </Box>

        {showPlaneResults && (
          <Box sx={{ mt: 2 }}>
            {loading ? (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress size={24} />
                <Typography variant="caption" sx={{ ml: 1 }}>
                  Calculating results...
                </Typography>
              </Box>
            ) : (
              <>
                <Typography variant="subtitle1" gutterBottom>
                  Results
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mb: 1,
                  }}
                >
                  {/* Plane Irradiance Results */}
                  {!!planeIrrRange && (
                    <>
                      {renderResult(
                        "Min Plane Irr",
                        planeIrrRange.minIrr,
                        "irradiance",
                      )}
                      {renderResult(
                        "Max Plane Irr",
                        planeIrrRange.maxIrr,
                        "irradiance",
                      )}
                    </>
                  )}
                </Box>
                {exposureTime ? (
                  <Box
                    sx={{
                      display: "flex",
                      gap: 2,
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    {!!planeIrrRange && (
                      <>
                        {renderResult(
                          "Min Dose",
                          planeIrrRange.minIrr * exposureTime,
                          "dose",
                        )}
                        {renderResult(
                          "Max Dose",
                          planeIrrRange.maxIrr * exposureTime,
                          "dose",
                        )}
                      </>
                    )}
                  </Box>
                ) : (
                  <Typography variant="body2" color="error">
                    Please set the exposure time to see the dose
                  </Typography>
                )}
              </>
            )}
          </Box>
        )}
      </CardContent>
    </Card>
  );
}
