import { SKIN_IRR_WEIGHTED_TO_UNWEIGHTED_RATIO } from "../constants";

// CONSTANTS

const IRR_X_VALS_HEIGHT = [2.25, 2.3, 2.35, 2.4, 2.5, 2.6, 2.75, 3];
export const IRR_X_VALS = IRR_X_VALS_HEIGHT.map((x) => x - 1.9 - 0.07); // Subtract detector and Zener height to get actual distance
export const IRR_Y_VALS_BOOST = [
  1.05e-2, 7.58e-3, 5.63e-3, 4.4e-3, 2.9e-3, 2.05e-3, 1.33e-3, 9.25e-4,
];
export const IRR_Y_VALS_SETTLED = [
  7.56e-3, 5.56e-3, 4.11e-3, 3.24e-3, 2.15e-3, 1.54e-3, 9.96e-4, 7.1e-4,
];
const ZENER_HEIGHT = 0.07;

const IRR_MIN_X = Math.min(...IRR_X_VALS);
const IRR_MAX_X = Math.max(...IRR_X_VALS);

export const ANG_X_RAD = [
  0.0, 0.08743, 0.17449, 0.26192, 0.34898, 0.43641, 0.52347, 0.6109, 0.69796,
  0.78539, 0.87282, 0.95988, 1.04731, 1.13437, 1.2218, 1.30886, 1.39629,
  1.48335, 1.57078,
];

export const ANG_Y_PERC = [
  1.0, 0.999, 0.991, 0.97, 0.894, 0.732, 0.611, 0.367, 0.158, 0.072, 0.045,
  0.037, 0.035, 0.03, 0.026, 0.023, 0.017, 0.012, 0.007,
];

const ANG_MIN_X = Math.min(...ANG_X_RAD);
const ANG_MAX_X = Math.max(...ANG_X_RAD);

// Algorithms

export class Akima {
  xis: Array<number>;
  yis: Array<number>;
  extrap_stat: string;

  constructor(
    xis: number[] = IRR_X_VALS,
    yis: number[],
    extrap_stat: string = "CONST",
  ) {
    if (xis.length !== yis.length) {
      throw new Error("X and Y arrays must have the same length");
    }
    if (xis.length < 3) {
      throw new Error("More points are required for interpolation");
    }

    this.xis = xis;
    this.yis = yis;
    this.extrap_stat = extrap_stat;
  }

  assert(condition) {
    if (!condition) {
      throw new Error("Assertion error");
    }
  }

  findFirstLn(x) {
    if (x > this.xis[this.xis.length - 1]) {
      return -1;
    }

    this.assert(this.xis.length >= 2);

    for (let i = this.xis.length - 2; i >= 0; i--) {
      if (this.xis[i] <= x) {
        return i;
      }
    }
    return -1;
  }

  calcSlope(x1, x2, y1, y2) {
    const numer = y2 - y1;
    const denom = x2 - x1;
    this.assert(denom != 0);
    return numer / denom;
  }

  akimaItp(x) {
    this.assert(this.xis.length >= 3);

    // --- Find i ---
    const i = this.findFirstLn(x);
    const out_of_bounds = i < 0 || i >= this.xis.length - 1;

    if (out_of_bounds) {
      // Out-of-bounds.
      if (this.extrap_stat === "CONST") {
        if (x < this.xis[0]) {
          return this.yis[0];
        } else {
          return this.yis[this.xis.length - 1];
        }
      }
      if (this.extrap_stat === "NaN") {
        return NaN;
      }
    }
    // --- Calculate slopes ---
    const x_i = this.xis[i];
    const y_i = this.yis[i];
    const x_ip1 = this.xis[i + 1];
    const y_ip1 = this.yis[i + 1];

    const m_i = this.calcSlope(x_i, x_ip1, y_i, y_ip1);
    let m_in2: number, m_in1: number, m_ip1: number, m_ip2: number;

    if (i === 0) {
      // Generate 2 slopes to the left.
      const x_ip2 = this.xis[i + 2];
      const y_ip2 = this.yis[i + 2];
      const m_ip1 = this.calcSlope(x_ip1, x_ip2, y_ip1, y_ip2);
      m_in1 = 2 * m_i - m_ip1;
      m_in2 = 2 * m_in1 - m_i;
    } else if (i === 1) {
      // Generate 1 slope to the left.
      const x_in1 = this.xis[i - 1];
      const y_in1 = this.yis[i - 1];

      m_in1 = this.calcSlope(x_in1, x_i, y_in1, y_i);
      m_in2 = 2 * m_in1 - m_i;
    } else {
      const x_in2 = this.xis[i - 2];
      const y_in2 = this.yis[i - 2];
      const x_in1 = this.xis[i - 1];
      const y_in1 = this.yis[i - 1];

      m_in2 = this.calcSlope(x_in2, x_in1, y_in2, y_in1);
      m_in1 = this.calcSlope(x_in1, x_i, y_in1, y_i);
    }

    if (i === this.xis.length - 2) {
      const x_in1 = this.xis[i - 1];
      const y_in1 = this.yis[i - 1];
      const m_in1 = this.calcSlope(x_in1, x_i, y_in1, y_i);

      m_ip1 = 2 * m_i - m_in1;
      m_ip2 = 2 * m_ip1 - m_i;
    } else if (i === this.xis.length - 3) {
      // Generate 1 slope to the right.
      const x_ip2 = this.xis[i + 2];
      const y_ip2 = this.yis[i + 2];

      m_ip1 = this.calcSlope(x_ip1, x_ip2, y_ip1, y_ip2);
      m_ip2 = 2 * m_ip1 - m_i;
    } else {
      const x_ip2 = this.xis[i + 2];
      const y_ip2 = this.yis[i + 2];
      const x_ip3 = this.xis[i + 3];
      const y_ip3 = this.yis[i + 3];

      m_ip1 = this.calcSlope(x_ip1, x_ip2, y_ip1, y_ip2);
      m_ip2 = this.calcSlope(x_ip2, x_ip3, y_ip2, y_ip3);
    }

    let s_i: number, s_ip1: number;

    const s_i_denom = Math.abs(m_ip1 - m_i) + Math.abs(m_in1 - m_in2);

    if (s_i_denom == 0) {
      s_i = (m_in1 + m_i) / 2;
    } else {
      s_i =
        (Math.abs(m_ip1 - m_i) * m_in1 + Math.abs(m_in1 - m_in2) * m_i) /
        s_i_denom;
    }

    const s_ip1_denom = Math.abs(m_ip2 - m_ip1) + Math.abs(m_i - m_in1);
    if (s_ip1_denom == 0) {
      s_ip1 = (m_i + m_ip1) / 2;
    } else {
      s_ip1 =
        (Math.abs(m_ip2 - m_ip1) * m_i + Math.abs(m_i - m_in1) * m_ip1) /
        s_ip1_denom;
    }

    // --- Calculate result polynomial ---

    const a_i = y_i;
    const b_i = s_i;
    const c_i = (3 * m_i - 2 * s_i - s_ip1) / (x_ip1 - x_i);

    let d_i = s_i + s_ip1 - 2 * m_i;
    d_i /= (x_ip1 - x_i) ** 2;

    const x_d = x - x_i;
    return a_i + b_i * x_d + c_i * x_d ** 2 + d_i * x_d ** 3;
  }
}

export function calcRadialWeightedIrr(distM: number): number {
  const akimaBoost = new Akima(IRR_X_VALS, IRR_Y_VALS_BOOST);
  const akimaSettled = new Akima(IRR_X_VALS, IRR_Y_VALS_SETTLED);

  // Check if distM is within bounds
  if (distM >= IRR_MIN_X && distM <= IRR_MAX_X) {
    return (
      (akimaBoost.akimaItp(distM) * 11.4) / 64 +
      (akimaSettled.akimaItp(distM) * (64 - 11.4)) / 64
    );
  }

  // For out-of-bounds distM, calculate the scaling factor a
  const boundaryX = distM < IRR_MIN_X ? IRR_MIN_X : IRR_MAX_X;
  const a =
    (akimaBoost.akimaItp(boundaryX) * 11.4) / 64 +
    (akimaSettled.akimaItp(boundaryX) * (64 - 11.4)) / 64;

  // Use inverse square root
  return a / (distM * distM);
}

export function calcIrrAngCoeff(angRad: number): number {
  const akima = new Akima(ANG_X_RAD, ANG_Y_PERC);

  // Check if angRad is within bounds
  if (angRad < ANG_MIN_X || angRad > ANG_MAX_X) {
    return 0;
  }

  return akima.akimaItp(angRad);
}

export function calcWeightedIrr(distM: number, angRad: number): number {
  const radialIrr = calcRadialWeightedIrr(distM);
  const angCoeff = calcIrrAngCoeff(angRad);
  return radialIrr * angCoeff;
}

export function calcUnweightedIrr(distM: number, angRad: number): number {
  const weightedIrr = calcWeightedIrr(distM, angRad);
  return weightedIrr * SKIN_IRR_WEIGHTED_TO_UNWEIGHTED_RATIO;
}

export function calculateDutyCycle(
  mountHeight: number,
  dectorHeight = 1.9,
  tBoost: number = 11.4,
  maxDosage: number = 30,
): { tBoost: number; tSettled: number; tLampOn: number; dutyCycle: number } {
  const evalDist = mountHeight - dectorHeight - ZENER_HEIGHT;
  const akimaBoost = new Akima(IRR_X_VALS, IRR_Y_VALS_BOOST);
  const akimaSettled = new Akima(IRR_X_VALS, IRR_Y_VALS_SETTLED);

  const EBoost = akimaBoost.akimaItp(evalDist);
  const ESettled = akimaSettled.akimaItp(evalDist);
  const maxLampOnTime = 64;

  // Calculate the total dosage per cycle
  const totalDosagePerCycle = maxDosage / 450;

  // Calculate the Boost mode dosage
  let boostDosage = EBoost * tBoost;

  // Calculate the remaining dosage available for the Settled mode
  let remainingDosage = totalDosagePerCycle - boostDosage;

  // Adjust tBoost if needed
  if (remainingDosage <= 0) {
    // Adjust tBoost to fit within the dosage limit
    tBoost = totalDosagePerCycle / EBoost;
    boostDosage = EBoost * tBoost;
    remainingDosage = totalDosagePerCycle - boostDosage;

    // If there's no remaining dosage left, return
    if (remainingDosage <= 0) {
      return {
        tBoost,
        tSettled: 0,
        tLampOn: tBoost,
        dutyCycle: tBoost / maxLampOnTime,
      };
    }
  }

  // Calculate the Settled mode time required
  let tSettled = remainingDosage / ESettled;
  let tLampOn = tSettled + tBoost;

  if (tLampOn > maxLampOnTime) {
    tSettled = maxLampOnTime - tBoost;
    tLampOn = maxLampOnTime;
  }

  // Calculate duty cycle as a percentage
  const dutyCycle = tLampOn / maxLampOnTime;
  return { tBoost, tSettled, tLampOn, dutyCycle };
}
