import React, { useEffect, useRef, useState } from "react";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import qs from "qs";
import Paper from "@mui/material/Paper";
import Switch from "@mui/material/Switch";
import Button from "@mui/material/Button";
import { AdminNetUtils, ZenerNetUtils, DeviceNetUtils } from "../../networking";
import {
  DeviceMetadata,
  ZenerStatus,
  ZenerControl,
  ColQueryOp,
} from "../../types";
import BoxModal from "../../components/BoxModal";
import Coredumps from "../../components/Coredumps";
import StatusGraph from "../../components/StatusGraph";
import DeviceAttributeEditor from "../../components/DeviceAttributeEditor";
import { PageUrl } from "../App";
import TypeToConfirmModal from "../../components/TypeToConfirmModal";
import "./DeviceDetails.css";
import Axios, { CancelTokenSource } from "axios";

interface StatusItemProps {
  name: string;
  value: string;
}
function StatusItem(props: StatusItemProps): React.ReactElement {
  const { name, value } = props;

  return (
    <div className="device-details-status-item">
      <span className="device-details-status-item-name">{name}</span>
      <span className="device-details-status-item-value">{value}</span>
    </div>
  );
}

interface ControlItemProps {
  name: string;
  value: boolean;
  toggleValue: (next: boolean) => void;
}
function ControlItem(props: ControlItemProps): React.ReactElement {
  const { name, value, toggleValue } = props;

  return (
    <div className="device-details-control-item">
      <span className="device-details-control-item-name">{name}</span>
      <Switch
        checked={value || false}
        onChange={() => toggleValue(!value)}
        size="medium"
      />
    </div>
  );
}

export interface DeleteDevicePanelProps {
  device: DeviceMetadata;
}
function DeleteDevicePanel(props: DeleteDevicePanelProps) {
  const { device } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const confirmationKey = "Confirm Delete";

  const nav = useNavigate();

  async function deleteDeviceHandler(deviceId: string) {
    if (device !== null) {
      await AdminNetUtils.deleteDevice(deviceId);
      nav(PageUrl.FleetOverview);
      window.location.reload();
    }
  }

  async function handleSuccess() {
    try {
      await deleteDeviceHandler(device.deviceId);
      enqueueSnackbar("Delete successful", { variant: "success" });
      setOpenConfirm(false);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      // eslint-disable-next-line no-console
      console.error(error);
      enqueueSnackbar(error.message, { variant: "error" });
    }
  }

  function handleFail() {
    enqueueSnackbar("Invalid entry", { variant: "error" });
  }

  return (
    <Paper classes={{ root: "delete-device" }}>
      <div className="delete">
        <Button
          classes={{ root: "confirm-buttons" }}
          variant="outlined"
          color="secondary"
          onClick={() => setOpenConfirm(true)}
        >
          Delete Device
        </Button>
        <TypeToConfirmModal
          open={openConfirm}
          setOpen={setOpenConfirm}
          onSuccess={handleSuccess}
          onFail={handleFail}
          confirmationMessage={`You are about to delete device: ${device.deviceId}. Enter '${confirmationKey}' to proceed:`}
          confirmationKey={confirmationKey}
        />
      </div>
    </Paper>
  );
}

export default function DeviceDetails(): React.ReactElement | null {
  const [device, setDevice] = useState<DeviceMetadata | null>(null);
  const [deviceStatus, setDeviceStatus] = useState<ZenerStatus | null>(null);
  const [deviceControl, setDeviceControl] = useState<ZenerControl | null>(null);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const getDevicesCTSRef = useRef<CancelTokenSource | null>(null);
  const getStatusesCTSRef = useRef<CancelTokenSource | null>(null);
  const getControlCTSRef = useRef<CancelTokenSource | null>(null);
  const descriptionUndefined = 
    device?.description === undefined || 
    device?.description === null || 
    device?.description === "";

  function cancelRequest(cts: React.MutableRefObject<CancelTokenSource>) {
    if (cts.current !== null) {
      cts.current.cancel();
    }
    cts.current = Axios.CancelToken.source();
  }

  async function fetchDevice(deviceId: string) {
    cancelRequest(getDevicesCTSRef);
    cancelRequest(getStatusesCTSRef);
    cancelRequest(getControlCTSRef);

    try {
      const nextDevices = await DeviceNetUtils.getDevices(
        {
          deviceId: { value: deviceId, op: ColQueryOp.Eq }, 
        },
        undefined,
        undefined,
        getDevicesCTSRef.current
      );
      if (nextDevices.length === 0) {
        return;
      }
      setDevice(nextDevices[0]);

      const nextDeviceStatuses = await ZenerNetUtils.getStatuses(
        [deviceId],
        getStatusesCTSRef.current
      );
      if (nextDeviceStatuses.length !== 0) {
        const nextDeviceStatus = nextDeviceStatuses[0];
        setDeviceStatus(nextDeviceStatus);
      }

      const nextDeviceControl = await ZenerNetUtils.getControl(
        deviceId,
        getControlCTSRef.current
      );
      if (nextDeviceControl !== null) {
        setDeviceControl(nextDeviceControl);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  useEffect(() => {
    const queryObj = qs.parse(window.location.search.slice(1));
    const deviceId = queryObj.device_id;
    fetchDevice(deviceId as string);
  }, [editModalOpen]);

  async function updateDeviceEnabled(nextEnabled: boolean) {
    if (device === null || deviceControl === null) {
      return;
    }
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    setDeviceControl((prev) => ({ ...prev!, uvLampEnabled: nextEnabled }));

    try {
      await ZenerNetUtils.postControl(device.deviceId, {
        uvLampEnabled: nextEnabled,
      });
      const nextDeviceStatuses = await ZenerNetUtils.getStatuses([
        device.deviceId,
      ]);
      if (nextDeviceStatuses.length !== 0) {
        setDeviceStatus(nextDeviceStatuses[0]);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  function handlePressEdit() {
    setEditModalOpen(true);
  }

  return (
    <div className="device-details">
      <Paper classes={{ root: "device-details-header-container" }}>
        {device !== null && deviceControl !== null && (
          <>
            <div className="device-details-header-row">
              <h1 className="device-details-device-id">{device.deviceId}</h1>
              <h3 className="device-details-device-version">
                {device.version}
              </h3>
            </div>
            <div className="device-details-header-row">
              <div>
                <h3 className="device-details-device-name">{device.name}</h3>
                <span className="device-details-device-owner-id">
                  Owned by:&nbsp;
                  {device.ownerId}
                </span>
                <div className="device-details-device-description">
                  Description (optional): {
                    descriptionUndefined ?
                      <span className="device-details-device-description-undefined">undefined</span>
                      : device.description
                  }
                </div>
              </div>
              <div>
                <div className="device-details-edit-button">
                  <Button variant="contained" onClick={handlePressEdit}>
                    Edit
                  </Button>
                </div>
                <ControlItem
                  name="Lamp Enabled"
                  value={deviceControl.uvLampEnabled}
                  toggleValue={updateDeviceEnabled}
                />
              </div>
            </div>
          </>
        )}
      </Paper>
      <Paper classes={{ root: "device-status-container" }}>
        {deviceStatus !== null && (
          <>
            <StatusItem
              name="Human Detected"
              value={deviceStatus.humanDetected.toString().toUpperCase()}
            />
            <StatusItem
              name="Lamp Firing"
              value={deviceStatus.uvDisinfecting.toString().toUpperCase()}
            />
          </>
        )}
      </Paper>
      {device !== null && <DeleteDevicePanel device={device} />}
      {device !== null && <DeviceAttributeEditor attribute="height" device={device} />}
      {device !== null && <StatusGraph deviceId={device.deviceId} />}
      {device !== null && <Coredumps deviceId={device.deviceId} />}
      <BoxModal
        open={editModalOpen}
        width={600}
        onClose={() => setEditModalOpen(false)}
      >
        <div className="edit-details-modal-header-row">
          <h3>
            Edit Zener Details
          </h3>
          <button
            className="edit-details-modal-close"
            type="button"
            onClick={() => setEditModalOpen(false)}
          >
            X
          </button>
        </div>
        <div className="edit-details-modal-input-container">
          {device !== null && <DeviceAttributeEditor attribute="name" device={device} />}
        </div>
        <div className="edit-details-modal-input-container">
          {device !== null && <DeviceAttributeEditor attribute="description" device={device} />}
        </div>
      </BoxModal>
    </div>
  );
}
