import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
import Axios, { CancelTokenSource } from "axios";
import { useNavigate, useLocation, Link } from "react-router-dom";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import { MaterialReactTable } from "material-react-table";
import {
  type MRT_ColumnDef,
  type MRT_PaginationState,
  type MRT_ColumnFiltersState,
  type MRT_RowSelectionState,
} from "material-react-table";
import { useSnackbar } from "notistack";
import * as JsonCompress from "compress-json";
import hash from "object-hash";

import { PageUrl } from "../App";
import FleetOverviewToolbar from "../../components/FleetOverviewToolbar";
import {
  ZenerNetUtils,
  TagsNetUtils,
  DeviceNetUtils,
  DeviceConnNetUtils,
} from "../../networking";
import {
  DeviceMetadata,
  ZenerStatus,
  DeviceTag,
  DeviceConnEvt,
  ColQueryOp,
} from "../../types";
import { UvxZenerState } from "../../capnp/ts_gen/uvx_zener.capnp";
import { zenerStateCapnpToStr } from "../../utils/util";

import "./FleetOverview.scss";

interface TableRow {
  metadata: DeviceMetadata;
  status?: ZenerStatus;
  tags: DeviceTag[];
  connEvt?: DeviceConnEvt;
}

function mapColLabelToQueryLabel(col: string): string {
  switch (col) {
    case "tags":
      return "tag_ids";
    default:
      return col;
  }
}

function mapQueryLabelToOp(col: string): ColQueryOp {
  switch (col) {
    case "tag_ids":
      return ColQueryOp.Overlap;
    default:
      return ColQueryOp.FuzzyEq;
  }
}

function colFiltersToQuery(
  colFilters: MRT_ColumnFiltersState,
): DeviceNetUtils.GetDevicesQuery {
  return colFilters.reduce(
    (acc: DeviceNetUtils.GetDevicesQuery, { id, value }) => {
      const colParts = id.split(".");
      const col = colParts[colParts.length - 1];

      const queryLabel = mapColLabelToQueryLabel(col);

      acc[queryLabel] = {
        value,
        op: mapQueryLabelToOp(queryLabel),
      };

      return acc;
    },
    {},
  );
}

interface PageState {
  columnFilters: MRT_ColumnFiltersState;
  pagination: MRT_PaginationState;
}

function serState(state: PageState): string {
  return JSON.stringify(JsonCompress.compress(state));
}

function deState(buf: string): PageState | null {
  try {
    const arr = JSON.parse(buf);
    return JsonCompress.decompress(arr);
  } catch (error) {
    console.error(error);
    return null;
  }
}

function genDeviceDetailsUrl(deviceId: string): string {
  return `${PageUrl.DeviceDetails}?device_id=${deviceId}`;
}

export default function FleetOverview(): React.ReactElement {
  const [devices, setDevices] = useState<TableRow[]>([]);
  const [devicesCount, setDevicesCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedDeviceIdsSet, setSelectedDeviceIdsSet] =
    useState<MRT_RowSelectionState>({});
  const [tags, setTags] = useState<DeviceTag[]>([]);
  const devicesMetadataCTSRef = useRef<CancelTokenSource | null>(null);
  const zenersStatusCTSRef = useRef<CancelTokenSource | null>(null);
  const devicesMetadataCountCTSRef = useRef<CancelTokenSource | null>(null);
  const tagsCTSRef = useRef<CancelTokenSource | null>(null);

  const nav = useNavigate();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const { columnFilters, pagination }: PageState = useMemo(() => {
    const defaultColumnFilters: MRT_ColumnFiltersState = [];
    const defaultPagination: MRT_PaginationState = {
      pageIndex: 0,
      pageSize: 10,
    };

    const defaultState: PageState = {
      columnFilters: defaultColumnFilters,
      pagination: defaultPagination,
    };

    const urlSearch = location.search;
    const query = new URLSearchParams(urlSearch);
    const serdState = query.get("state");
    if (serdState === null) {
      return defaultState;
    }
    const nextPageState: PageState | null = deState(serdState);
    if (nextPageState === null) {
      return defaultState;
    }

    if (!nextPageState.pagination) {
      nextPageState.pagination = defaultPagination;
    }

    return nextPageState;
  }, [location.search]);

  const setColumnFilters = useCallback(
    (
      genColFilters: (prev: MRT_ColumnFiltersState) => MRT_ColumnFiltersState,
    ) => {
      const nextColumnFilters: MRT_ColumnFiltersState =
        genColFilters(columnFilters);
      const prevHash = hash(columnFilters);
      const nextHash = hash(nextColumnFilters);

      if (prevHash === nextHash) {
        return;
      }

      const nextPageState: PageState = {
        columnFilters: nextColumnFilters,
        pagination,
      };

      const serdState = serState(nextPageState);

      const searchParams = new URLSearchParams(location.search);
      searchParams.set("state", serdState);
      const pathname = location.pathname;
      nav({ pathname, search: searchParams.toString() });
    },
    [location, nav, pagination, columnFilters],
  );

  const setPagination = useCallback(
    (genPagination: (prev: MRT_PaginationState) => MRT_PaginationState) => {
      const nextPagination = genPagination(pagination);
      const prevHash = hash(pagination);
      const nextHash = hash(nextPagination);

      if (prevHash === nextHash) {
        return;
      }

      const nextPageState: PageState = {
        columnFilters,
        pagination: nextPagination,
      };

      const serdState = serState(nextPageState);

      const searchParams = new URLSearchParams(location.search);
      searchParams.set("state", serdState);
      const pathname = location.pathname;
      nav({ pathname, search: searchParams.toString() });
    },
    [location, nav, pagination, columnFilters],
  );

  const selectedDeviceIds: string[] = useMemo(
    () => Object.keys(selectedDeviceIdsSet),
    [selectedDeviceIdsSet],
  );

  function cancelRequest(cts: React.MutableRefObject<CancelTokenSource>) {
    if (cts.current !== null) {
      cts.current.cancel();
    }
    cts.current = Axios.CancelToken.source();
  }

  const fetchTags = useCallback(async () => {
    cancelRequest(tagsCTSRef);
    try {
      const nextTags = await TagsNetUtils.getTags(
        undefined,
        tagsCTSRef.current,
      );
      setTags(nextTags);
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error.message, { variant: "error" });
    }
  }, []);

  useEffect(() => {
    fetchTags();
  }, []);

  const fetchPage = useCallback(async () => {
    if (tags.length === 0) {
      return;
    }
    cancelRequest(devicesMetadataCTSRef);
    cancelRequest(devicesMetadataCountCTSRef);
    cancelRequest(zenersStatusCTSRef);

    const query = colFiltersToQuery(columnFilters);

    try {
      setLoading(true);

      // Fetch device metadatas.
      const nextDevicesMetadata = await DeviceNetUtils.getDevices(
        query,
        pagination.pageIndex * pagination.pageSize,
        pagination.pageSize,
        devicesMetadataCTSRef.current,
      );
      const nextDevicesCount = await DeviceNetUtils.getDevicesCount(
        query,
        devicesMetadataCountCTSRef.current,
      );
      if (nextDevicesMetadata === null || nextDevicesCount === null) {
        return;
      }
      setDevicesCount(nextDevicesCount);

      const nextDeviceIds = nextDevicesMetadata.map((dm) => dm.deviceId);

      // Fetch connection evts.
      const nextConnEvts: Record<string, DeviceConnEvt> = (
        await DeviceConnNetUtils.getDevicesConnected(nextDeviceIds)
      ).reduce((acc: Record<string, DeviceConnEvt>, curr: DeviceConnEvt) => {
        acc[curr.device_id] = curr;
        return acc;
      }, {}) as Record<string, DeviceConnEvt>;

      // Fetch device statuses.
      const nextZenersStatus = (
        await ZenerNetUtils.getStatuses(nextDeviceIds)
      ).reduce((acc: Record<string, ZenerStatus>, curr: ZenerStatus) => {
        acc[curr.deviceId] = curr;
        return acc;
      }, {}) as Record<string, ZenerStatus>;

      const tagsMap = tags.reduce(
        (acc: Record<string, DeviceTag>, curr: DeviceTag) => {
          acc[curr.tagId] = curr;
          return acc;
        },
        {},
      );

      // Merge data.
      const nextDevices = nextDevicesMetadata.map((deviceMetadata) => {
        const deviceId = deviceMetadata.deviceId;
        const status = nextZenersStatus[deviceId];
        const connEvt = nextConnEvts[deviceId];
        const deviceTags = deviceMetadata.tagIds
          .map((tagId) => tagsMap[tagId])
          .filter((t) => t !== undefined);
        const row: TableRow = {
          metadata: deviceMetadata,
          status,
          tags: deviceTags,
          connEvt: connEvt,
        };
        return row;
      });
      setDevices(nextDevices);
    } catch (error) {
      if (Axios.isCancel(error)) {
        return;
      }
      console.error(error);
      enqueueSnackbar(error.message, { variant: "error" });
    } finally {
      setLoading(false);
    }
  }, [pagination.pageIndex, pagination.pageSize, columnFilters, tags]);

  useEffect(() => {
    fetchPage();
  }, [fetchPage]);

  const deleteTagHdlr = useCallback(
    async (deviceId: string, tagId: number) => {
      try {
        await DeviceNetUtils.removeDeviceTags([
          {
            deviceIds: [deviceId],
            tagId,
          },
        ]);
        enqueueSnackbar("Deleted tag", { variant: "success" });
        await fetchPage();
      } catch (error) {
        console.error(error);
        enqueueSnackbar(error.message, { variant: "error" });
      }
    },
    [fetchPage],
  );

  const columns = useMemo<MRT_ColumnDef<TableRow>[]>(
    () => [
      {
        header: "Device ID",
        accessorKey: "metadata.deviceId",
        enableClickToCopy: true,
      },
      {
        header: "Name",
        accessorKey: "metadata.name",
        enableClickToCopy: true,
      },
      {
        header: "State",
        size: 140,
        accessorKey: "status.zenerState",
        enableColumnFilter: false,
        Cell: ({ cell }) => {
          const val = cell.getValue<UvxZenerState | undefined>();
          if (val !== undefined) {
            return zenerStateCapnpToStr(val);
          } else {
            return "UNDEFINED";
          }
        },
      },
      {
        header: "Tags",
        accessorKey: "tags",
        filterVariant: "multi-select",
        filterSelectOptions: tags.map((t) => ({
          text: t.label,
          value: t.tagId,
        })),
        Cell: ({ cell }) => {
          const deviceTags = cell.getValue<DeviceTag[]>();
          const deviceId = cell.row.getValue<string>("metadata.deviceId");
          return (
            <div className="tags-cell-container">
              {deviceTags.map((t) => (
                <Chip
                  key={t.tagId}
                  label={t.label}
                  onDelete={() => deleteTagHdlr(deviceId, t.tagId)}
                />
              ))}
            </div>
          );
        },
      },
      {
        header: "Owner",
        accessorKey: "metadata.ownerId",
        enableClickToCopy: true,
        size: 140,
        Cell: ({ cell }) => {
          const ownerId = cell.getValue<string>();
          const ownerIdParts = ownerId.split("-");
          if (ownerIdParts.length < 1) {
            return ownerId;
          }
          const ownerIdLast = ownerIdParts[ownerIdParts.length - 1];
          return <span title={ownerId}>{ownerIdLast}</span>;
        },
      },
      {
        header: "Version",
        accessorKey: "metadata.version",
        enableClickToCopy: true,
        size: 80,
      },
      {
        header: "Connected",
        accessorKey: "connEvt.connected",
        size: 80,
        enableColumnFilter: false,
        // filterVariant: "select",
        // filterSelectOptions: [
        // { text: "Connected", value: "true" },
        // { text: "Disconnected", value: "false" },
        // ],
        Cell: ({ cell }) =>
          cell.getValue<boolean>() ? "Connected" : "Disconnected",
      },
    ],
    [deleteTagHdlr, tags],
  );

  return (
    <div className="fleet-overview">
      <FleetOverviewToolbar
        devices={devices.map((r) => r.metadata)}
        selectedDeviceIds={selectedDeviceIds}
        fetchDevices={fetchPage}
        fetchTags={fetchTags}
        tags={tags}
      />
      <div className="table-container">
        <MaterialReactTable
          columns={columns}
          data={devices}
          getRowId={(r: TableRow) => r?.metadata?.deviceId}
          initialState={{ showColumnFilters: true }}
          enableSorting={false}
          enableRowSelection
          enableMultiRowSelection
          manualFiltering
          manualPagination
          onPaginationChange={setPagination}
          onColumnFiltersChange={setColumnFilters}
          onRowSelectionChange={setSelectedDeviceIdsSet}
          rowCount={devicesCount}
          state={{
            isLoading: loading,
            pagination,
            rowSelection: selectedDeviceIdsSet,
          }}
          enableRowActions
          renderRowActions={({ row }) => (
            <Box sx={{ display: "flex", gap: "1rem" }}>
              <Link
                to={genDeviceDetailsUrl(
                  row.getValue<string>("metadata.deviceId"),
                )}
                target="_blank"
              >
                <OpenInNewIcon />
              </Link>
            </Box>
          )}
        />
      </div>
    </div>
  );
}
