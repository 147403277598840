import React, { useEffect, useRef } from "react";
import { Box, Typography, Paper } from "@mui/material";
import * as THREE from "three";
import { IrrRange } from "../../hooks/threeJs/useIrrHeatmap";
import { convertWeightedIrrToUnweighted } from "../../utils/util";

interface HeatmapColorkeyProps {
  irrRange: IrrRange | null;
  lutTexture: THREE.DataTexture;
}

export default function HeatmapColorkey({
  irrRange,
  lutTexture,
}: HeatmapColorkeyProps) {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (!canvasRef.current || !lutTexture || !irrRange) {
      return;
    }

    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    if (!ctx) {
      return;
    }

    const gradientWidth = canvas.width;
    const gradientHeight = canvas.height;

    // Draw the gradient
    const imageData = ctx.createImageData(gradientWidth, gradientHeight);
    const lutData = lutTexture.image.data as Uint8Array;

    for (let i = 0; i < gradientWidth; i++) {
      const t = 1 - i / (gradientWidth - 1);
      const index = Math.floor(t * 255) * 4;
      const r = lutData[index];
      const g = lutData[index + 1];
      const b = lutData[index + 2];

      for (let j = 0; j < gradientHeight; j++) {
        const pixelIndex = (j * gradientWidth + i) * 4;
        imageData.data[pixelIndex] = r;
        imageData.data[pixelIndex + 1] = g;
        imageData.data[pixelIndex + 2] = b;
        imageData.data[pixelIndex + 3] = 255; // Alpha channel
      }
    }
    ctx.putImageData(imageData, 0, 0);
  }, [lutTexture, irrRange]);

  if (!irrRange) {
    return null;
  }

  return (
    <Paper elevation={3} sx={{ padding: 2, mt: 3, textAlign: "center" }}>
      <Typography variant="subtitle1" gutterBottom>
        Irradiance Color
      </Typography>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch",
          padding: "0px 24px",
          width: "100%",
          maxWidth: "650px",
          margin: "0 auto",
        }}
      >
        <canvas
          ref={canvasRef}
          width={400}
          height={10}
          style={{ borderRadius: 4 }}
        />

        <Box sx={{ display: "flex", justifyContent: "space-between", mt: 1 }}>
          <Typography variant="caption">
            {convertWeightedIrrToUnweighted(irrRange.minIrr).toExponential(3)}{" "}
            W/m²
          </Typography>
          <Typography variant="caption">
            {convertWeightedIrrToUnweighted(irrRange.maxIrr).toExponential(3)}{" "}
            W/m²
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
}
