import React, { useEffect, useState } from "react";
import "./SaveZenerPositionsModal.css";
import BoxModal from "../BoxModal";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { statusToString, stringToStatus } from "../../utils/util";
import { ReviewStatus, Simulation } from "../../types";

interface SaveZenerPositionsModalProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  saveChanges: (simulation: Simulation) => void;
  updateChanges: (simulation: Simulation) => void;
  simulation: Simulation | null;
  handleSimulationUpdate: (updatedSimulation: Simulation) => void;
}

export default function SaveZenerPositionsModal({
  visible,
  setVisible,
  saveChanges,
  updateChanges,
  simulation,
  handleSimulationUpdate,
}: SaveZenerPositionsModalProps): React.ReactElement | null {
  const [localSimulation, setLocalSimulation] = useState<Simulation | null>(
    simulation,
  );

  useEffect(() => {
    setLocalSimulation(simulation);
  }, [simulation]);

  const handleFieldChange = (
    key: keyof Simulation,
    value: string | ReviewStatus,
  ) => {
    const updatedSimulation = { ...localSimulation, [key]: value };
    setLocalSimulation(updatedSimulation);
    handleSimulationUpdate(updatedSimulation); // Notify parent about the change
  };

  const isExistingSimulation = Boolean(localSimulation?.scan_id);
  const handleAction = isExistingSimulation ? updateChanges : saveChanges;
  const actionLabel = isExistingSimulation
    ? "Update Zener Positions"
    : "Save Zener Positions";

  return (
    <BoxModal open={visible} width={400} onClose={() => setVisible(false)}>
      <div className="modal-text">{"Save Zener Positions"}</div>
      <FormControl>
        <InputLabel id="status-select-helper-label">
          Simulation Status
        </InputLabel>
        <Select
          className="modal-text-input"
          labelId="status-select-helper-label"
          value={statusToString(localSimulation?.review_status) ?? ""}
          label="Simulation Status"
          onChange={(evt) =>
            handleFieldChange("review_status", stringToStatus(evt.target.value))
          }
        >
          <MenuItem value={"Pending"}>Pending</MenuItem>
          <MenuItem value={"Finished"}>Finished</MenuItem>
          <MenuItem value={"Info Needed"}>Info Needed</MenuItem>
        </Select>
        <TextField
          className="modal-text-input"
          fullWidth
          label="Simulation Name"
          value={localSimulation?.name || ""}
          onChange={(evt) => handleFieldChange("name", evt.target.value)}
        />
      </FormControl>
      <Button
        onClick={() => localSimulation && handleAction(localSimulation)}
        color="success"
        disabled={!localSimulation}
      >
        {actionLabel}
      </Button>
    </BoxModal>
  );
}
