import * as UvxZenerCapnp from "./capnp/ts_gen/uvx_zener.capnp";
export interface ScanFiles {
  json: string;
  mp4: string;
  obj: string;
}
export interface RoomDimension {
  x: number; // Width
  y: number; // Depth
  z: number; // Height
}
export interface RoomScan {
  scan_id: string;
  owner_id: string;
  name: string;
  upload_date: Date;
  s3_key: string;
  files: ScanFiles;
}
export interface ZenerPosition {
  x: number;
  y: number;
  z: number;
}
export enum ReviewStatus {
  Finished = "finished",
  Pending = "pending",
  InfoNeeded = "info_needed",
}
export interface Simulation {
  simulation_id: string;
  scan_id: string;
  owner_id: string;
  name: string;
  zener_positions: Array<ZenerPosition>;
  upload_date: Date;
  review_status: ReviewStatus;
}

export interface DeviceTag {
  tagId: number;
  ownerId: string;
  label: string;
}

export interface DeviceMetadata {
  deviceId: string;
  name: string | null;
  description: string | null;
  ownerId: string;
  connected: boolean;
  version: string;
  tagIds: number[];
  height: number;
}
export interface ZenerStatus {
  deviceId: string;
  humanDetected: boolean;
  uvDisinfecting: boolean;
  evtTimestamp: Date;
  zenerState: UvxZenerCapnp.UvxZenerState;
}

export interface ZenerControl {
  uvLampEnabled: boolean;
}

export interface CoredumpMetadata {
  deviceId: string;
  coredumpId: string;
  coredumpLen: number;
  createdAt: Date;
}

export interface FwVersionHistEntry {
  version: string;
  count: number;
}

export interface FwMetadata {
  version: string;
  size: number;
  tagsFilter?: number[];
}

export interface DeviceConnEvt {
  device_id: string;
  connected: boolean;
  evt_ts: Date;
}

export interface OtaJob {
  job_id: string;
  status: string;
  firmware_version: string;
  job_start_ts: Date;
  tag_ids: number[];
}

export enum OtaEvt {
  ChunkRequest = "OTA_CHUNK_REQ",
  Cancelled = "OTA_CANCELLED",
  Error = "OTA_ERROR",
  Start = "OTA_START",
}

export interface OtaDevices {
  device_id: string;
  job_id: string;
  evt_type: OtaEvt;
  latest_evt_ts: Date;
  offset: number;
  size: number;
}

export enum ColQueryOp {
  Eq = "eq",
  FuzzyEq = "fuzzy_eq",
  Gt = "gt",
  Ln = "ln",
  Overlap = "overlap",
}

export interface ColQuery<T> {
  value: T | T[];
  op: ColQueryOp;
}

export type DbQuery<T> = {
  [K in keyof T]?: ColQuery<T[K]>;
};

export enum Time {
  Seconds = "s",
  Minutes = "m",
  Hours = "h",
}

export enum IrrUnit {
  WPerMsq = "W/m² / J/m²",
  WPerCMSq = "W/cm² / J/cm²",
}
