import React from "react";
import { Tooltip, Box, Typography } from "@mui/material";
import "./HeatmapTooltip.scss";
import { IrrUnit } from "../../types";
import { convertIrrValue } from "../../utils/threejs";
import { convertWeightedIrrToUnweighted } from "../../utils/util";
import { RaycastPoint } from "../../hooks/threeJs/useIrrHeatmap";

export interface TooltipData {
  x: number;
  y: number;
  pointData?: {
    raycastPoint: RaycastPoint;
    dose?: number;
  } | null;
  message?: string;
}

interface HeatmapTooltipProps {
  tooltip: TooltipData | null;
  visible?: boolean;
  selectedUnit: IrrUnit;
  dutyCycle: number | null;
}

export default function HeatmapTooltip({
  tooltip,
  visible,
  selectedUnit,
  dutyCycle,
}: HeatmapTooltipProps) {
  if (!tooltip || !visible) {
    return;
  }

  const { x, y, pointData, message } = tooltip;

  const renderPointData = () => {
    if (!pointData) {
      return;
    }

    const { raycastPoint, dose } = pointData;
    const { zenersWithLOSRaycastResults = [], totalIrradiance } = raycastPoint;

    const zeners = zenersWithLOSRaycastResults.map((r) => r.name);
    const distances = zenersWithLOSRaycastResults.map((r) => r.distance);

    const renderResult = (
      label: string,
      value: number | null,
      type: string,
    ) => {
      if (value === null) return null;

      let formattedValue = "";
      let unit = "";

      switch (type) {
        case "irradiance":
          formattedValue =
            convertIrrValue(value, selectedUnit)?.toExponential(3) ?? "";
          unit = selectedUnit.startsWith("W/m²") ? "W/m²" : "W/cm²";
          break;
        case "dose":
          formattedValue =
            convertIrrValue(value, selectedUnit)?.toFixed(6) ?? "";
          unit = selectedUnit.startsWith("W/m²") ? "J/m²" : "J/cm²";
          break;
        default:
          formattedValue = value.toString();
      }
      return (
        <Typography variant="body2" sx={{ mb: 0.5 }}>
          {label}: {formattedValue} {unit}
        </Typography>
      );
    };

    const dutyCycleVal = dutyCycle || 1;

    return (
      <>
        {zeners.length > 0 && (
          <Typography variant="body2" sx={{ mb: 0.5 }}>
            <strong>Zeners:</strong> {zeners.join(", ")}
          </Typography>
        )}
        {distances.length > 0 && (
          <Typography variant="body2" sx={{ mb: 0.5 }}>
            <strong>Distances:</strong>{" "}
            {distances.map((d) => d.toFixed(2)).join(", ")} m
          </Typography>
        )}
        {totalIrradiance !== undefined &&
          renderResult("Weighted Irradiance", totalIrradiance, "irradiance")}
        {totalIrradiance !== undefined &&
          renderResult(
            "Unweighted Irradiance",
            convertWeightedIrrToUnweighted(totalIrradiance),
            "irradiance",
          )}
        {dose !== undefined &&
          renderResult("Weighted Dose", dose * dutyCycleVal, "dose")}
        {dose !== undefined &&
          renderResult(
            "Unweighted Dose",
            convertWeightedIrrToUnweighted(dose * dutyCycleVal),
            "dose",
          )}
      </>
    );
  };

  return (
    <Tooltip
      componentsProps={{
        tooltip: {
          sx: { ".MuiTooltip-tooltip": { maxWidth: 400 }, maxWidth: 400 },
        },
      }}
      open={true}
      title={
        <Box sx={{ padding: 1, width: "100%" }}>
          {renderPointData()}
          {message && <Typography variant="body2">{message}</Typography>}
        </Box>
      }
      placement="top"
      arrow
    >
      <div className="tooltip" style={{ top: y, left: x }} />
    </Tooltip>
  );
}
